import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@libs/shared/utilities';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  constructor(@Inject(WINDOW) private window: Window, @Inject(DOCUMENT) public document: Document) {}

  onSm$: Observable<boolean> = this.onBreakpoint$('--sm-breakpoint');
  onMd$: Observable<boolean> = this.onBreakpoint$('--md-breakpoint');
  onLg$: Observable<boolean> = this.onBreakpoint$('--lg-breakpoint');
  onXl$: Observable<boolean> = this.onBreakpoint$('--xl-breakpoint');

  isSm(): boolean {
    return this.isBreakpoint('--sm-breakpoint');
  }
  isMd(): boolean {
    return this.isBreakpoint('--md-breakpoint');
  }
  isLg(): boolean {
    return this.isBreakpoint('--lg-breakpoint');
  }
  isXl(): boolean {
    return this.isBreakpoint('--xl-breakpoint');
  }

  private isBreakpoint(breakpointName: string): boolean {
    return this.isWindowAtBreakpoint(this.window, breakpointName);
  }

  private onBreakpoint$(breakpointName: string): Observable<boolean> {
    return fromEvent(this.window, 'resize').pipe(
      map(({ target }) => this.isWindowAtBreakpoint(target as Window, breakpointName)),
      distinctUntilChanged()
    );
  }

  private isWindowAtBreakpoint(window: Window, breakpointName: string): boolean {
    return window.innerWidth >= parseInt(this.getCssPropertyValue(breakpointName));
  }

  private getCssPropertyValue(propertyName: string): string {
    return this.window.getComputedStyle(this.document.documentElement).getPropertyValue(propertyName);
  }
}
