import { pushNotificationActions } from './../actions/push-notification.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsState } from '../models/notifications.models';

export const notificationsInitialState: NotificationsState = {
  pushToken: undefined,
};

const notificationsReducerFn = createReducer<NotificationsState>(
  notificationsInitialState,
  on(pushNotificationActions.updatePushToken, (state, { pushToken }) => {
    return { ...state, pushToken };
  })
);

export function notificationsReducer(state: NotificationsState, action: Action): NotificationsState {
  return notificationsReducerFn(state, action);
}
