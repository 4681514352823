import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableSlotPipe } from './available-slot.pipe';

@NgModule({
  declarations: [AvailableSlotPipe],
  exports: [AvailableSlotPipe],
  imports: [CommonModule],
})
export class AvailableSlotPipeModule {}
