import { Component } from '@angular/core';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'kody-notice-popover',
  templateUrl: './notice-popover.component.html',
  styleUrls: ['./notice-popover.component.scss'],
})
export class NoticePopoverComponent {
  readonly faChevronRight = faChevronRight;
  text: string;
  title: string;

  constructor(private popoverController: PopoverController) {}

  done(): void {
    this.popoverController.dismiss();
  }
}
