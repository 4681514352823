import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FeatureFlagValue } from '../../types/feature-flag-value.type';
import { FeatureFlagsState, featureFlagsStoreKey } from '../models/feature-flags-state.model';

const selectFeatureFlagsState = createFeatureSelector<FeatureFlagsState>(featureFlagsStoreKey);

const userIdentified = createSelector(selectFeatureFlagsState, (state) => state?.userIdentified);

const isActive = (key: string, expectedValue: FeatureFlagValue = true) =>
  createSelector(selectFeatureFlagsState, (state) => {
    if (!key) return true;
    const value = state.flags[key];
    return value === expectedValue;
  });

const getFlagPayload = (key: string) =>
  createSelector(selectFeatureFlagsState, (state) => {
    if (!key) return true;
    return state.flags[key];
  });

export const featureFlagsSelectors = {
  userIdentified,
  isActive,
  getFlagPayload,
};
