import { consumerMenuPageActions } from '../actions/consumer-menu-page.actions';
import { ConsumerMenuPageState } from '../models/consumer-menu-state.model';
import { Action, createReducer, on } from '@ngrx/store';
import { consumerMenuApiActions } from '../actions/consumer-menu-api.actions';

export const initialConsumerMenuPageState: ConsumerMenuPageState = {
  merchantStore: undefined,
  merchantStoreCategories: undefined,
  menuItems: undefined,
  availableSlots: undefined,
};

const consumerMenuPageReducerFn = createReducer<ConsumerMenuPageState>(
  undefined,
  on(consumerMenuPageActions.enterPage, () => ({
    ...initialConsumerMenuPageState,
  })),
  on(consumerMenuApiActions.getMenuCategoriesSuccess, (state, { menuCategories }) => ({
    ...state,
    merchantStoreCategories: menuCategories,
    selectedCategory: menuCategories[0].restaurantMenuCategoryId, // Initially select the first category
  })),
  on(consumerMenuApiActions.getMenuItemsSuccess, (state, { menuItems }) => ({
    ...state,
    menuItems,
  })),
  on(consumerMenuPageActions.filterByMenuCategory, (state, { menuCategoryId }) => ({
    ...state,
    selectedCategory: menuCategoryId,
  })),
  on(consumerMenuPageActions.searchMenuItems, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
  })),
  on(consumerMenuApiActions.filterMenuCategoriesSuccess, (state, { menuItems }) => ({
    ...state,
    menuItems,
  })),
  on(consumerMenuPageActions.leavePage, () => undefined)
);

export function consumerMenuPageReducer(state: ConsumerMenuPageState, action: Action): ConsumerMenuPageState {
  return consumerMenuPageReducerFn(state, action);
}
