import { KodyOrderAppState, OrderTypes } from '@libs/shared/types';
import { ItemUtility } from '@libs/shared/utilities';
import { createSelector } from '@ngrx/store';

const appState = (state: KodyOrderAppState) => state;

const selectMerchantStore = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.merchantStore;
});

const selectTable = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.table;
});

const selectCart = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.currentCart;
});

const selectStoreItemGroups = createSelector(appState, (state: KodyOrderAppState) =>
  state.appState.currentCart?.itemGroups?.map((itemGroup) => ({
    ...itemGroup,
    price: ItemUtility.getItemPrice(itemGroup.item, itemGroup.addons, itemGroup.quantity),
  }))
);

const selectItemGroupsTotalPrice = createSelector(appState, (state) => {
  if (!state.appState.currentCart?.itemGroups) return 0;
  const itemGroupPrices = state.appState.currentCart?.itemGroups.map(({ addons, item, quantity }) =>
    ItemUtility.getItemPrice(item, addons, quantity)
  );

  return itemGroupPrices.reduce((acc, p) => acc + p, 0);
});

const selectCartCount = createSelector(appState, (state: KodyOrderAppState) => {
  if (!state.appState.currentCart?.itemGroups) return 0;
  return state.appState.currentCart?.itemGroups.reduce((acc, { quantity }) => acc + quantity, 0);
});

const selectCurrentItemQuantity = createSelector(appState, (state: KodyOrderAppState) => {
  if (!state.appState.currentCart) {
    return;
  }
  return (state.appState.currentCart?.itemGroups || []).reduce((obj, { item, quantity }) => {
    if (obj[item.merchantItemId]) {
      obj[item.merchantItemId] = obj[item.merchantItemId] + quantity;
    } else {
      obj[item.merchantItemId] = quantity;
    }
    return obj;
  }, {} as { [key: string]: number });
});

const selectClickCollectSlot = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.clickAndCollectSlot;
});

const selectAvailableClickCollectSlots = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.availableClickAndCollectSlots;
});

const serviceChargeEnabled = createSelector(appState, (state: KodyOrderAppState) => {
  return (
    state.appState.merchantStore?.serviceCharge && [OrderTypes.table, OrderTypes.counter].includes(state.appState.currentCart?.orderType)
  );
});

const serviceChargeValue = createSelector(appState, serviceChargeEnabled, selectItemGroupsTotalPrice, (state, enabled, itemPrice) => {
  if (!enabled) return 0;
  return state.appState.serviceChargeFixed ?? (state.appState.serviceChargePercent / 100) * itemPrice;
});

const serviceChargeOptions = createSelector(appState, selectItemGroupsTotalPrice, (state, itemPrice) => {
  const basePercent: number = state.appState.merchantStore?.serviceChargeAmount ?? 0;
  const options = [basePercent, basePercent + 2.5, basePercent + 5];
  return options.map((percent) => ({ percent, value: (percent / 100) * itemPrice }));
});

const serviceChargeFixed = createSelector(appState, serviceChargeEnabled, (state, enabled) =>
  enabled ? state.appState.serviceChargeFixed : null
);

const serviceChargePercent = createSelector(appState, serviceChargeEnabled, (state, enabled) =>
  enabled ? state.appState.serviceChargePercent : null
);

const serviceChargeUnlockThresholdPercent = createSelector(appState, (state) => {
  return (state.appState.merchantStore?.lockServiceChargeThreshold ?? 0) / 100;
});

const discount = createSelector(appState, (state) => state.appState.discount);

const discountValue = createSelector(discount, selectItemGroupsTotalPrice, (discount, totalPrice) => {
  if (discount?.amount) {
    return Math.min(discount.amount, totalPrice);
  }
  if (discount?.percentage) {
    return (totalPrice * discount.percentage) / 100;
  }
  return 0;
});

const totalPrice = createSelector(
  selectItemGroupsTotalPrice,
  serviceChargeValue,
  discountValue,
  (itemPrice, serviceChargeValue, discountValue) => itemPrice - discountValue + serviceChargeValue
);

const ageConfirmationRequired = createSelector(
  selectMerchantStore,
  selectCart,
  (store, cart) => store?.requiresAgeConfirmation && cart?.itemGroups?.some((itemGroup) => itemGroup.item.isEighteenPlus)
);

const acknowledgedEvents = createSelector(appState, (state: KodyOrderAppState) => {
  return state.appState.acknowledgedEvents;
});

export const appStateSelectors = {
  selectMerchantStore,
  selectTable,
  selectCart,
  selectStoreItemGroups,
  selectItemGroupsTotalPrice,
  selectCartCount,
  selectCurrentItemQuantity,
  selectClickCollectSlot,
  selectAvailableClickCollectSlots,
  totalPrice,
  serviceChargeOptions,
  serviceChargeEnabled,
  serviceChargeValue,
  serviceChargeFixed,
  serviceChargePercent,
  serviceChargeUnlockThresholdPercent,
  discount,
  discountValue,
  ageConfirmationRequired,
  acknowledgedEvents,
};
