import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingSkeletonComponent } from './components/loading-skeleton/loading-skeleton.component';

@NgModule({
  declarations: [LoadingComponent, LoadingSkeletonComponent],
  imports: [CommonModule, IonicModule],
  exports: [LoadingComponent, LoadingSkeletonComponent],
})
export class LoadingModule {}
