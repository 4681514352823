import { Inject, Injectable } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { appStateActions, AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';
import { Environment, ItemGroupAddons } from '@libs/shared/types';
import { APP_ENVIRONMENT, ErrorMonitoringService } from '@libs/shared/utilities';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ConsumerAddonModalComponent } from '../../containers/consumer-addon-modal/consumer-addon-modal.component';
import { AddonApiService } from '../../services/addon-api/addon-api.service';
import { consumerMenuAddonsActions } from '../actions/consumer-menu-addons.actions';

@Injectable()
export class ConsumerMenuAddonEffects {
  constructor(
    private actions$: Actions,
    private modalController: ModalController,
    private addonApiService: AddonApiService,
    private errorMonitoringService: ErrorMonitoringService,
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    private loadingController: LoadingController,
    private appStateFacadeService: AppStateFacadeService
  ) {}

  getAddons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuAddonsActions.getAddons),
      concatLatestFrom(() => [this.appStateFacadeService.getMerchantStore(), this.appStateFacadeService.getTable()]),
      exhaustMap(([{ item }, merchantStore, table]) => {
        const baseApiUrl = `${this.environment.baseApiUrl}/web/`;
        return this.addonApiService.getAddons(baseApiUrl, item.merchantItemId, merchantStore.merchantId).pipe(
          map((addons) => {
            if (addons) {
              return consumerMenuAddonsActions.getAddonsSuccess({ addons, item });
            } else {
              return appStateActions.addItemToCart({ item, addons: {} as ItemGroupAddons, merchantStore, table });
            }
          }),
          catchError((error: Error) => {
            this.errorMonitoringService.handleError(error);
            this.modalController.dismiss();
            return of(consumerMenuAddonsActions.getAddonsFailed());
          })
        );
      })
    )
  );

  hideLoadingSpinner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consumerMenuAddonsActions.getAddonsSuccess, appStateActions.addItemToCart),
        map(async () => {
          const overlay = await this.loadingController.getTop();
          if (overlay) {
            this.loadingController.dismiss();
          }
        })
      ),
    { dispatch: false }
  );

  getAddonsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consumerMenuAddonsActions.getAddonsSuccess),
        map(async ({ addons, item }) => {
          const modal = await this.modalController.create({
            component: ConsumerAddonModalComponent,
            cssClass: 'kp-modal-lg',
            backdropDismiss: true,
            componentProps: {
              item,
              itemAddons: addons,
            },
          });
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  addAddonsToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuAddonsActions.addAddonsToCart),
      concatLatestFrom(() => [this.appStateFacadeService.getMerchantStore(), this.appStateFacadeService.getTable()]),
      tap(() => {
        this.modalController.dismiss();
      }),
      map(([{ item, addons }, merchantStore, table]) => appStateActions.addItemToCart({ item, addons, merchantStore, table }))
    )
  );
}
