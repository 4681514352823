import { gql } from 'apollo-angular';

export const getAllConfigurableMerchantCategories = gql`
  query ConfigurableStoreMenuCategoriesByMerchantIdQuery($merchantStoreId: UUID) {
    configurableMenuCategoriesByMerchantStoreId(merchantStoreId: $merchantStoreId) {
      nodes {
        statusId
        merchantId
        name
        restaurantMenuCategoryId
      }
    }
  }
`;

export interface GetAllConfigurableMerchantCategoriesQuery {
  configurableMenuCategoriesByMerchantStoreId: {
    nodes: {
      statusId: string;
      merchantId: string;
      name: string;
      restaurantMenuCategoryId: string;
    };
  };
}
