import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginPageState, LoginState, loginStateKey } from '../models/login-state.model';

const selectLoginState = createFeatureSelector<LoginState>(loginStateKey);
const selectLoginPageState = createSelector(selectLoginState, (state: LoginState) => state?.loginPage);

const selectUnauthorisedState = createSelector(selectLoginPageState, (state: LoginPageState) => state?.error);
const selectLoadingState = createSelector(selectLoginPageState, (state: LoginPageState) => state?.loading);

export const loginSelectors = {
  selectUnauthorisedState,
  selectLoadingState,
};
