import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { kodyOrderBaseRoutes } from '@libs/shared/types';
import { AppLegacyRedirectGuard } from './app-legacy-redirect.guard';
import { AppRoutingGuard } from './app-routing.guard';

const { root, table, clickCollect, counter, payment, orderConfirmed } = kodyOrderBaseRoutes;

const routes: Routes = [
  {
    canActivate: [AppRoutingGuard],
    path: `${root}/:storeId`,
    children: [
      {
        path: table,
        loadChildren: () => import('@libs/app-kody-order/feature-menu').then((m) => m.CustomerMenuPageModule),
      },
      {
        path: clickCollect,
        loadChildren: () => import('@libs/app-kody-order/feature-menu').then((m) => m.CustomerMenuPageModule),
      },
      {
        path: counter,
        loadChildren: () => import('@libs/app-kody-order/feature-menu').then((m) => m.CustomerMenuPageModule),
      },
      {
        path: `${payment}/:orderId`,
        loadChildren: () => import('@libs/feature-payment').then((m) => m.PaymentPageModule),
      },
      {
        path: `${orderConfirmed}/:orderId`,
        loadChildren: () => import('@libs/feature-payment').then((m) => m.OrderConfirmedPageModule),
      },
    ],
  },
  // Handle legacy routes (remove when no longer required)
  {
    path: 'main',
    children: [
      {
        path: '**',
        canActivate: [AppLegacyRedirectGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '',
          },
        ],
      },
    ],
  },
  {
    path: 'table',
    children: [
      {
        path: '**',
        canActivate: [AppLegacyRedirectGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '',
          },
        ],
      },
    ],
  },
  {
    path: 'counter',
    children: [
      {
        path: '**',
        canActivate: [AppLegacyRedirectGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '',
          },
        ],
      },
    ],
  },
  {
    path: 'click-collect',
    children: [
      {
        path: '**',
        canActivate: [AppLegacyRedirectGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '',
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
