import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ChipColors } from './chip.component.models';

@Component({
  selector: 'kody-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input() color: ChipColors;
  @Input() unavailable: boolean;
  @Input() size?: 'sm' | 'md' | 'lg' = 'md';
}
