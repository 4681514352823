/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faExchange,
  faInfoCircle,
  faRedoAlt,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { map } from 'rxjs/operators';
import { IonRouterOutlet, ModalController, ModalOptions, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { CollectStatus, ItemGroupAddons, StoreItem, StoreItemStatusId } from '@libs/shared/types';
import { ResizedEvent } from 'angular-resize-event';
import { BehaviorSubject, Observable } from 'rxjs';
import { ItemInformationModalComponent } from '../item-information-modal/item-information-modal.component';
import { consumerMenuAddonsActions, getCurrentCart, ItemUtility } from '@libs/shared/utilities';

interface QuantityMap {
  [key: string]: number;
}

@Component({
  selector: 'kody-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild('itemName', { read: ElementRef, static: false })
  itemName: ElementRef;
  item$: BehaviorSubject<StoreItem>;
  _item: StoreItem;
  @Input()
  get item(): StoreItem {
    return this._item;
  }
  set item(val: StoreItem) {
    this._item = val;
  }

  // Used by payment and order details pages
  @Input() addons: ItemGroupAddons;

  // Used by orders, click & collect, transactions and payments pages
  @Input() descriptionEnabled = true;
  @Input() editable = true;

  // Used by Orders Click & Collect Page Only
  @Input() picking = false;
  @Input() showInfo = true;

  // Used by order-details-items.component & transaction.details.page only
  @Input() available = true;

  // Used by payment page only
  @Input() checkout = false;
  @Input() quantity = 0;

  // Used by item-found-popover.component  only
  @Input() divider = true;

  // Used by order-details-items.component only
  @Input() refundCount: number;
  @Input() refund = false;

  // Used by menu-items.component only
  @Input() isMerchantView?: boolean;
  @Input() index: number;
  // End: Used by menu-items.component only

  // Parent component should handle this once refactored
  @Input() baseApiUrlCms: string;

  // Used by orders pages only
  @Output() checkboxClicked: EventEmitter<StoreItem> = new EventEmitter<StoreItem>();

  // Not used
  @Output() openAddonModal = new EventEmitter<void>();

  // Used by everything except orders/payment
  cartItemQuantityMap$: Observable<QuantityMap> = this.store.select(getCurrentCart).pipe(
    map((currentCart) => {
      return (currentCart.itemGroups || []).reduce((obj, { item, quantity }) => {
        if (obj[item.merchantItemId]) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          obj[item.merchantItemId] = obj[item.merchantItemId] + quantity;
        } else {
          obj[item.merchantItemId] = quantity;
        }
        return obj;
      }, {} as QuantityMap);
    })
  );

  price = 0;
  barcodeData: string;
  baseImageUrl: string;
  readonly faTimesCircle = faTimesCircle;
  readonly faInfoCircle = faInfoCircle;
  readonly faChevronUp = faChevronUp;
  readonly faChevronDown = faChevronDown;
  readonly faRedoAlt = faRedoAlt;
  readonly faCheck = faCheck;
  readonly faExchange = faExchange;
  readonly faTimes = faTimes;
  collectStatus = CollectStatus;
  storeItemStatusId = StoreItemStatusId;
  clicked = {};
  singleLineTitle = false;

  constructor(
    private store: Store,
    private popoverController: PopoverController,
    private routerOutlet: IonRouterOutlet,
    private modalController: ModalController
  ) {
    this.item$ = new BehaviorSubject(null);
  }

  ngOnInit(): void {
    this.baseImageUrl = `${this.baseApiUrlCms}/merchantItem/image/`;
  }

  ngOnChanges({ item, addons }: SimpleChanges): void {
    if (item || addons) {
      this.price = ItemUtility.getItemPrice(this.item, this.addons, 1);
    }
    if (item && item.currentValue) {
      this.item$.next(item.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.item$.complete();
  }

  close(): void {
    this.popoverController.dismiss();
  }

  addItem(item: StoreItem, index: number): void {
    if (this.available && this.editable && !this.checkout && !this.clicked[index] && !this.picking && !this.refund) {
      this.store.dispatch(consumerMenuAddonsActions.addItem({ item }));
      this.disableItem(index);
    }
  }

  async createModal(modalParams: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create(modalParams);
    return modal;
  }

  async showModal(modalParams: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this.createModal(modalParams);
    modal.present();

    return modal;
  }

  disableItem(index: number): void {
    this.clicked[index] = true;
    setTimeout(() => {
      this.clicked[index] = false;
    }, 300);
  }

  editItemCount(item: StoreItem): void {
    if (this.available && this.editable) {
      this.store.dispatch(consumerMenuAddonsActions.updateCartItemQuantity({ item, addons: this.addons, quantity: this.quantity }));
    }
  }

  async presentModal(item: StoreItem, event: Event): Promise<void> {
    event.stopPropagation();
    if (this.available && this.editable) {
      await this.showModal({
        component: ItemInformationModalComponent,
        cssClass: 'modal-container-class',
        backdropDismiss: true,
        swipeToClose: true,
        componentProps: { item },
        showBackdrop: true,
        presentingElement: this.routerOutlet.nativeEl,
      });
    }
  }

  checkBoxTap(item: StoreItem, event: Event): void {
    event.stopPropagation();
    if (this.available && this.editable) {
      this.checkboxClicked.next(item);
    }
  }

  onNameResize({ newRect }: ResizedEvent): void {
    const lineHeight = parseInt(getComputedStyle(this.itemName.nativeElement).lineHeight.slice(0, -2));
    // Disable description if name is over 1 line
    this.singleLineTitle = newRect.height <= lineHeight;
  }

  // Allows title / description to be full width if there is no end content
  mainColumnWidth(): number {
    const hasEndContent = !this.editable || this.refund || this.picking || this.checkout || this.item.image;
    return hasEndContent ? 9 : 12;
  }
}
