import { createAction, props } from '@ngrx/store';
import { User } from '@libs/shared/types';
import { HttpErrorResponse } from '@angular/common/http';

const loginSuccess = createAction('[Login Api] Login success', props<{ user: User }>());
const loginFailure = createAction('[Login Api] Login failure', props<{ error: HttpErrorResponse }>());
const forgotPasswordSuccess = createAction('[Login Api] Forgot password success', props<{ email: string }>());
const forgotPasswordFailure = createAction('[Login Api] Forgot password failure', props<{ error: HttpErrorResponse }>());
const unauthorised = createAction('[Login Api] Unauthorised');
export const loginApiActions = {
  loginSuccess,
  loginFailure,
  unauthorised,
  forgotPasswordSuccess,
  forgotPasswordFailure,
};
