import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

const deviceRegistrationError = createAction(
  '[Authentication Interceptor] Device Registration Error',
  props<{ error: HttpErrorResponse }>()
);

const notAuthenticatedError = createAction('[Authentication Interceptor] Not Authenticated Error', props<{ error: HttpErrorResponse }>());

export const authenticationInterceptorActions = {
  deviceRegistrationError,
  notAuthenticatedError,
};
