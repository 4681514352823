import { HttpErrorResponse } from '@angular/common/http';
import { ClickCollectSlot, MerchantStore, OrderTypes } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

const getMerchantStoreSuccess = createAction(
  '[App API] Get merchant store success',
  props<{ merchantStore: MerchantStore; orderType: OrderTypes; table?: string }>()
);

const getMerchantStoreFailure = createAction('[App API] Get merchant store failure', props<{ error: HttpErrorResponse }>());

const getAvailableClickCollectSlotsSuccess = createAction(
  '[App API] Get available click and collect slots success',
  props<{ availableSlots: ClickCollectSlot[] }>()
);

const getAvailableClickCollectSlotsFailure = createAction(
  '[App API] Get available click and collect slots failure',
  props<{ error: HttpErrorResponse }>()
);

export const appStateApiActions = {
  getMerchantStoreSuccess,
  getMerchantStoreFailure,
  getAvailableClickCollectSlotsSuccess,
  getAvailableClickCollectSlotsFailure,
};
