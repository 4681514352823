import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsumerMenuAddonsState, consumerMenuAddonsStateKey } from '../models/consumer-menu-addons.model';

const selectConsumerMenuAddonsState = createFeatureSelector<ConsumerMenuAddonsState>(consumerMenuAddonsStateKey);

const selectAddonModalAddonsState = () =>
  createSelector(selectConsumerMenuAddonsState, (menuUtilitiesState: ConsumerMenuAddonsState) => menuUtilitiesState.addons);

export const consumerMenuAddonsSelector = {
  selectAddonModalAddonsState,
};
