import { ApplePayLineItem } from './apple-pay-line-item.interface';
import { ApplePayPaymentContact } from './apple-pay-payment-contact.interface';
import { ApplePayPaymentMethod } from './apple-pay-payment-method.interface';
import { ApplePayPayment } from './apple-pay-payment.interface';
import { ApplePayShippingMethod } from './apple-pay-shipping-method.interface';

export interface ApplePaySession {
  /**
   * A callback function that is automatically called when the payment UI is dismissed with an error.
   */
  oncancel: (event: Event) => void;

  /**
   * A callback function that is automatically called when the user has authorized the Apple Pay payment, typically via TouchID.
   */
  onpaymentauthorized: (event: ApplePayPaymentAuthorizedEvent) => void;

  /**
   * A callback function that is automatically called when a new payment method is selected.
   */
  onpaymentmethodselected: (event: ApplePayPaymentMethodSelectedEvent) => void;

  /**
   * A callback function that is called when a shipping contact is selected in the payment sheet.
   */
  onshippingcontactselected: (event: ApplePayShippingContactSelectedEvent) => void;

  /**
   * A callback function that is automatically called when a shipping method is selected.
   */
  onshippingmethodselected: (event: ApplePayShippingMethodSelectedEvent) => void;

  /**
   * A callback function that is automatically called when the payment sheet is displayed.
   */
  onvalidatemerchant: (event: ApplePayValidateMerchantEvent) => void;

  /**
   * Aborts the current Apple Pay session.
   */
  abort(): void;

  /**
   * Begins the merchant validation process.
   */
  begin(): void;

  /**
   * Call after the merchant has been validated.
   * @param merchantSession - An opaque message session object.
   */
  completeMerchantValidation(merchantSession: any): void;

  /**
   * Call when a payment has been authorized.
   * @param status - The status of the payment.
   */
  completePayment(status: number): void;

  /**
   * Call after a payment method has been selected.
   * @param newTotal - An Apple​Pay​Line​Item dictionary representing the total price for the purchase.
   * @param newLineItems - A sequence of Apple​Pay​Line​Item dictionaries.
   */
  completePaymentMethodSelection(newTotal?: ApplePayLineItem | unknown, newLineItems?: ApplePayLineItem[]): void;

  /**
   * Call after a shipping contact has been selected.
   * @param status - The status of the shipping contact update.
   * @param newShippingMethods - A sequence of ApplePayShippingMethod dictionaries.
   * @param newTotal - An Apple​Pay​Line​Item dictionary representing the total price for the purchase.
   * @param newLineItems - A sequence of Apple​Pay​Line​Item dictionaries.
   */
  completeShippingContactSelection(request: {
    newTotal?: ApplePayLineItem;
    newShippingMethods?: ApplePayShippingMethod[];
    errors?: unknown;
    newLineItems?: ApplePayLineItem[];
  }): void;

  /**
   * Call after the shipping method has been selected.
   * @param status - The status of the shipping method update.
   * @param newTotal - An Apple​Pay​Line​Item dictionary representing the total price for the purchase.
   * @param newLineItems - A sequence of Apple​Pay​Line​Item dictionaries.
   */
  completeShippingMethodSelection(status?: number | unknown, newTotal?: ApplePayLineItem, newLineItems?: ApplePayLineItem[]): void;
}

export abstract class ApplePayPaymentAuthorizedEvent extends Event {
  /**
   * The payment token used to authorize a payment.
   */
  readonly payment: ApplePayPayment;
}

/**
 * The Apple​Pay​Payment​Method​Selected​Event class defines the attributes contained by the ApplePaySession.onpaymentmethodselected callback function.
 */
abstract class ApplePayPaymentMethodSelectedEvent extends Event {
  /**
   * The card used to complete a payment.
   */
  readonly paymentMethod: ApplePayPaymentMethod;
}

/**
 * The Apple​Pay​Shipping​Contact​Selected​Event class defines the attributes contained by the ApplePaySession.onshippingcontactselected callback function.
 */
abstract class ApplePayShippingContactSelectedEvent extends Event {
  /**
   * The shipping address selected by the user.
   */
  readonly shippingContact: ApplePayPaymentContact;
}

/**
 * The Apple​Pay​Shipping​Method​Selected​Event class defines the attribute contained by the ApplePaySession.onshippingmethodselected callback function.
 */
abstract class ApplePayShippingMethodSelectedEvent extends Event {
  /**
   * The shipping method selected by the user.
   */
  readonly shippingMethod: ApplePayShippingMethod;
}

/**
 * The Apple​Pay​Validate​Merchant​Event class defines the attributes contained by the ApplePaySession.onvalidatemerchant callback function.
 */
export abstract class ApplePayValidateMerchantEvent extends Event {
  /**
   * The URL used to validate the merchant server.
   */
  readonly validationURL: string;
}
