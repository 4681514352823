import { Injectable } from '@angular/core';
import { PopoverController, PopoverOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  constructor(private popoverController: PopoverController) {}

  async present({
    translucent = false,
    showBackdrop = true,
    backdropDismiss = true,
    animated = false,
    cssClass = 'kp-popover',
    ...rest
  }: PopoverOptions): Promise<HTMLIonPopoverElement> {
    const popover = await this.popoverController.create({
      translucent,
      showBackdrop,
      backdropDismiss,
      cssClass,
      animated,
      ...rest,
    });
    await popover.present();
    return popover;
  }
}
