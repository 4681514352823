import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TimelineHorizontalComponent } from './timeline-horizontal.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, IonicModule],
  declarations: [TimelineHorizontalComponent],
  exports: [TimelineHorizontalComponent],
})
export class TimelineHorizontalComponentModule {}
