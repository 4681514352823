import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastController: ToastController) {}

  async success<T = any>(options: ToastOptions): Promise<OverlayEventDetail<T>> {
    return await this.present({
      ...options,
      color: 'success',
    });
  }

  async danger<T = any>(options: ToastOptions): Promise<OverlayEventDetail<T>> {
    return await this.present({
      ...options,
      color: 'danger',
    });
  }

  dismiss(): void {
    this.toastController.dismiss();
  }

  async present<T = any>({ duration = 2000, message, color, position = 'top', buttons }: ToastOptions): Promise<OverlayEventDetail<T>> {
    const toast = await this.toastController.create({
      color,
      duration,
      message,
      position,
      buttons,
    });
    await toast.present();
    return toast.onWillDismiss();
  }
}
