import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { kodyOrderBaseRoutes, OrderType, RestaurantOrder } from '@libs/shared/types';

@Injectable({
  providedIn: 'root',
})
export class KodyOrderRouterUtilityService {
  constructor(private router: Router) {}

  isPaymentRoute(): boolean {
    return this.router.url.split('/')[this.router.url.split('/').length - 1] === kodyOrderBaseRoutes.payment;
  }

  getEntryRouteFromOrder(order: RestaurantOrder): string {
    const prefix = `${kodyOrderBaseRoutes.root}/${order.merchantStoreId}/`;
    const typeToUrlMap: Record<Extract<OrderType, 'order_at_table' | 'order_click_collect' | 'order_at_counter'>, string> = {
      order_at_table: `${kodyOrderBaseRoutes.table}?${kodyOrderBaseRoutes.table}=${order.tableNumber || 0}`,
      order_click_collect: kodyOrderBaseRoutes.clickCollect,
      order_at_counter: kodyOrderBaseRoutes.counter,
    };
    const suffix: string = typeToUrlMap[order.orderType];
    return `${prefix}${suffix}`;
  }
}
