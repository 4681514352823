import { ofType, Actions } from '@ngrx/effects';
import { Creator } from '@ngrx/store/src/models';
import { take, switchMap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActionCreator, Action } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class RxjsHelperService {
  /**
   * Returns whatever value emits synchronously upon subscribing to the Observable, or `undefined` if no value is emitted synchronously.
   *
   * This is syntactic sugar around `observable.pipe(take(1)).subscribe` for when you are certain your Observable will emit synchronously.
   */
  synchronousValue = <T>(observable: Observable<T>): T | undefined => {
    let value: T | undefined;
    const subs = observable.pipe(take(1)).subscribe((v) => {
      value = v;
    });
    subs.unsubscribe();
    return value;
  };

  /**
   * Listen for actions only while a specific page is open (based on its enter/leave page actions being fired)
   */
  whileOnPage = <B extends object>(
    actions$: Actions<Action>,
    enterPage: ActionCreator,
    leavePage: ActionCreator,
    actionsToObserve: ActionCreator<string, Creator<any[], B>>[]
  ): Observable<B> =>
    actions$.pipe(
      ofType(enterPage),
      switchMap(() => {
        return actions$.pipe(ofType(...actionsToObserve), takeUntil(actions$.pipe(ofType(leavePage))));
      })
    );
}
