import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kody-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NoDataComponent {
  @Input() image: string;
  @Input() title: string;
  @Input() message: string;
}
