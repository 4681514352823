import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoreItem } from '@libs/shared/types';
import { ItemInformationModalComponent } from '@libs/shared/ui';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private modalController: ModalController) {}

  async showInformationModal(item: StoreItem): Promise<void> {
    const modal = await this.modalController.create({
      component: ItemInformationModalComponent,
      cssClass: 'kp-modal-lg',
      backdropDismiss: true,
      swipeToClose: true,
      componentProps: { item },
      showBackdrop: true,
    });
    return await modal.present();
  }
}
