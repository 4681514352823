import { ClickCollectSlot, InitializeCartModel, MerchantStore } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

export const GetMerchantStoreByIdSuccess = createAction('[User] Get Merchant Store By Id Success', props<{ payload: MerchantStore }>());

export const CommonHttpErrorResponse = createAction('[App] Common http error response', props<{ error: any }>());

export const SetAvailableSlots = createAction('[Cart] Set available slots', props<{ availableSlots: ClickCollectSlot[] }>());

export const SetCurrentRetailer = createAction('[User] Set Current Retailer', props<{ payload: InitializeCartModel }>());

export const GetStoreById = createAction('[User] Get Merchant Store By Id', props<{ storeId: string }>());

export const GetStoreAvailableSlots = createAction('[User] Get Store Available Slots', props<{ payload: string }>());
