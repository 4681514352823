import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kody-store-closed-popover',
  templateUrl: './store-closed-popover.component.html',
  styleUrls: ['./store-closed-popover.component.scss'],
})
export class StoreClosedPopoverContainerComponent {
  readonly faChevronRight = faChevronRight;

  text: string;
  title: string;
  image: string;
  isDarkMode: boolean;

  constructor(private popoverController: PopoverController) {}

  done(): void {
    this.popoverController.dismiss();
  }
}
