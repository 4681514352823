import { ClickCollectSlot, Card, Cart, OrderTypes, RestaurantOrder } from '@libs/shared/types';
import { ItemUtility } from '@libs/shared/utilities';
import { createSelector } from '@ngrx/store';

interface KodyOrderAppState {
  currentCart: Cart;
  currentRestaurantTable: string;
  currentCounterAvailableSlots: ClickCollectSlot[];
  currentCounterSelectedSlot: ClickCollectSlot;
  currentRestaurantOrderDetails: RestaurantOrder;
  cards: Card[];
  googlePayAvailable: boolean;
  applePayAvailable: boolean;
  loading: boolean;
}

const appState = (state: { appState: KodyOrderAppState }) => state.appState;

/** Deprecated in favour of AppStateSelectors */
export const getBasketCount = createSelector(appState, (state) => {
  if (!state.currentCart?.itemGroups) return 0;
  return state.currentCart?.itemGroups.reduce((acc, { quantity }) => acc + quantity, 0);
});

/** Deprecated in favour of AppStateSelectors */
export const getAvailableSlots = createSelector(appState, (state) => {
  return state.currentCounterAvailableSlots;
});

/** Deprecated: global loading states are bad.  Do not use */
export const isLoading = createSelector(appState, (state) => {
  return state.loading;
});
