import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, @Inject(DOCUMENT) public document: Document) {
    // Fixes unresolved Angular issue where you cannot inject the Render2 provider into a service (https://github.com/angular/angular/pull/9393)
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  inject(src: string): HTMLElement {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  remove(script: HTMLElement): void {
    this.renderer.removeChild(this.document.body, script);
  }
}
