import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';
import { PingRequest } from '@libs/shared/types';

export const PING = new InjectionToken<PingRequest>('An abstraction over global window Ping object added by the cordova-plugin-ping', {
  factory: () => {
    const { defaultView } = inject(DOCUMENT);

    if (!defaultView) {
      throw new Error('Window is not available');
    }

    const { Ping } = defaultView as any;
    return Ping as PingRequest;
  },
});
