<ion-badge
  *ngFor="let category of categories; trackBy: trackBy; let $i = index"
  class="menu-categories__category"
  [color]="selectedCategory === category.restaurantMenuCategoryId ? primaryColor : secondaryColor"
  (click)="selectCategory.emit(category)"
  (kodyLongPress)="longPressCategory.emit(category)"
  [longPressEnabled]="longPressCategory.observers.length > 0"
  [attr.kp-object]="'menu_category_' + $i"
  kodyRippleEffect
>
  {{ category.name }}
  <ng-container *ngTemplateOutlet="endContent; context: { $implicit: category }"> </ng-container>
</ion-badge>
