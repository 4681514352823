import { ItemGroup, ItemGroupAddons, StockItemAddOn, StoreItem } from '@libs/shared/types';
import { isEmpty } from 'lodash';

export class ItemUtility {
  static getItemPrice(item: StoreItem, addons: ItemGroupAddons, quantity = 1): number {
    let price = 0;
    price += parseFloat(item.price);

    if (!isEmpty(addons)) {
      Object.entries(addons).forEach(([, { quantity, item }]) => {
        price += parseFloat(item.price) * quantity;
      });
    }

    return price * quantity;
  }

  static getItemGroups(items: StoreItem[]): ItemGroup[] {
    return items.reduce((itemGroups, item) => {
      if (!!item.parentItemId) {
        // item is an add-on
        return itemGroups;
      }
      const addons = item.addonItems.length ? this.getAddonGroup(item.addonItems, items) : {};
      return itemGroups.concat({
        item,
        addons,
        quantity: item.quantity,
        price: ItemUtility.getItemPrice(item, addons, item.quantity),
      });
    }, [] as ItemGroup[]);
  }

  private static getAddonGroup(addonItems: StockItemAddOn[], storeItems: StoreItem[]): ItemGroupAddons {
    return addonItems.reduce((itemGroupAddons, addonItem) => {
      return {
        ...itemGroupAddons,
        [addonItem.merchantItemId]: {
          quantity: addonItem.quantity,
          item: storeItems.find((i) => i.merchantItemId === addonItem.merchantItemId), // find add-on item
        },
      } as ItemGroupAddons;
    }, {} as ItemGroupAddons);
  }
}
