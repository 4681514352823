import { StoreItem } from '@libs/shared/types';
import { gql } from 'apollo-angular';

// TODO: Use generated types once working
export const getMerchantItemsByStoreId = gql`
  query GetMerchantItemsByStoreId($merchantStoreId: UUID) {
    merchantItemsByStoreId(merchantStoreId: $merchantStoreId) {
      nodes {
        barcode
        count
        description
        isCustom
        isEatIn
        isGlutenFree
        isOnOffer
        isTakeout
        isVegan
        isVegetarian
        itemId
        menuCategoryId
        menuCategoryOrderIndex
        merchantId
        merchantItemId
        merchantStoreIds
        name
        newPrice
        offerTypeId
        price
        weight
        isClickCollect
        isEighteenPlus
        image {
          fileName
          mimeType
        }
        addonItems
      }
    }
  }
`;

export interface GetMerchantItemsByStoreIdQuery {
  merchantItemsByStoreId: {
    nodes: StoreItem[];
  };
}
