import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kody-exchange-button',
  templateUrl: './exchange-button.component.html',
  styleUrls: ['./exchange-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeButtonComponent {
  @Output() exchange = new EventEmitter<void>();
  readonly faExchange = faExchange;

  onExchange(): void {
    this.exchange.emit();
  }
}
