import { Component } from '@angular/core';
import { faTrash, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { StoreItem } from '@libs/shared/types';

@Component({
  selector: 'kody-item-information-modal',
  templateUrl: 'item-information-modal.component.html',
  styleUrls: ['item-information-modal.component.scss'],
})
export class ItemInformationModalComponent {
  readonly faTrash = faTrash;
  readonly faTimes = faTimes;
  item: StoreItem;

  constructor(public modalController: ModalController) {}

  dismissModal() {
    this.modalController.dismiss();
  }
}
