import { ItemAddonResponse, ItemGroupAddons, StoreItem } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

const openAddonModal = createAction('[Consumer Menu Addons] Open addon modal', props<{ item: StoreItem }>());

const addAddonsToCart = createAction('[Consumer Menu Addons] Add addons to cart', props<{ item: StoreItem; addons: ItemGroupAddons }>());

const leaveAddonsModal = createAction('[Consumer Menu Addons] Modal closed');

const getAddons = createAction('[Consumer Addons API] Get item addons', props<{ item: StoreItem }>());

const getAddonsSuccess = createAction('[Consumer Addons API] Get addons success', props<{ addons: ItemAddonResponse; item: StoreItem }>());

const getAddonsFailed = createAction('[Consumer Addons API] Get addons failed');

export const consumerMenuAddonsActions = {
  getAddons,
  openAddonModal,
  getAddonsSuccess,
  getAddonsFailed,
  addAddonsToCart,
  leaveAddonsModal,
};
