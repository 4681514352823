import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClickCollectSlot, MerchantStore } from '@libs/shared/types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreApiService {
  constructor(private httpClient: HttpClient) {}

  getMerchantStore(baseApiUrl: string, merchantStoreId: string): Observable<MerchantStore> {
    const url = `${baseApiUrl}merchant-stores/${merchantStoreId}`;
    return this.httpClient.get<MerchantStore>(url);
  }

  getClickCollectSlots(baseApiUrl: string, merchantStoreId: string): Observable<ClickCollectSlot[]> {
    const url = `${baseApiUrl}merchant-stores/${merchantStoreId}/timeslots`;
    return this.httpClient.get<ClickCollectSlot[]>(url);
  }
}
