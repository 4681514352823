import { createAction, props } from '@ngrx/store';

const userEmailConfirmationRequired = createAction(
  '[Authentication Guard] User Email Confirmation Required',
  props<{ email: string; userId: string }>()
);

const userNotAuthenticated = createAction('[Authentication Guard] User Not Authenticated');

export const authenticationGuardActions = {
  userNotAuthenticated,
  userEmailConfirmationRequired,
};
