import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PingResponse } from '../../interfaces/ping-response.interface';

const ping = createAction('[Device] Ping');
const pingSuccess = createAction('[Device] Ping Success', props<{ payload: PingResponse }>());
const pingError = createAction('[Device] Ping Error', props<{ payload: HttpErrorResponse }>());
const deviceRegError = createAction('[Device] Device Registration Error', props<{ error: Error }>());

export const deviceActions = {
  ping,
  pingSuccess,
  pingError,
  deviceRegError,
};
