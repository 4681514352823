import { createAction, props } from '@ngrx/store';

const enterPage = createAction('[Forgot Password Page] Enter page');
const leavePage = createAction('[Forgot Password Page] Leave page');
const resetPassword = createAction('[Forgot Password Page] Reset password', props<{ email: string }>());

export const forgotPasswordPageActions = {
  enterPage,
  leavePage,
  resetPassword,
};
