import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FeatureFlagsEffects } from './effects/feature-flags.effects';
import { featureFlagsStoreKey } from './models/feature-flags-state.model';
import { featureFlagsReducer } from './reducers/feature-flags.reducer';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(featureFlagsStoreKey, featureFlagsReducer), EffectsModule.forFeature([FeatureFlagsEffects])],
})
export class FeatureFlagsStoreModule {}
