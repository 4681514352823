import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'kody-shared-ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  readonly closeIcon = faTimes;
  @Input() title: string;
  @Input() titleSize: 'small' | 'large' = 'large';
  @Input() subheader = false;

  constructor(public modalController: ModalController) {}
}
