import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  constructor() {}

  step({ increment, boundary, isAdding, value }: { increment: number; boundary: number; isAdding: boolean; value: number }): number {
    const newVal: number = isAdding ? value + increment : value - increment;
    const isOutOfBoundary = isAdding ? newVal > boundary : newVal < boundary;
    return isOutOfBoundary ? boundary : newVal;
  }
}
