import { consumerMenuPageActions } from '../actions/consumer-menu-page.actions';
import { Inject, Injectable } from '@angular/core';
import { Actions, ofType, createEffect, concatLatestFrom } from '@ngrx/effects';
import { map, switchMap, catchError, mergeMap, tap, filter, withLatestFrom, take, takeUntil } from 'rxjs/operators';
import { combineLatest, from, of } from 'rxjs';
import { consumerMenuApiActions } from '../actions/consumer-menu-api.actions';
import { appStateActions, AppStateFacadeService, CommonHttpErrorResponse } from '@libs/app-kody-order/utility-app-state';
import { LoadingController } from '@ionic/angular';
import { consumerMenuAddonsActions } from '../actions/consumer-menu-addons.actions';
import { APP_ENVIRONMENT, SessionStorageService } from '@libs/shared/utilities';
import { Environment, OrderTypes, storeIdSessionStorageKey, UserSession } from '@libs/shared/types';
import { ConsumerMenuApiService } from '../../services/consumer-menu-api/consumer-menu-api.service';
import { ConsumerMenuFacadeService } from '../services/consumer-menu-facade/consumer-menu-facade.service';

@Injectable()
export class ConsumerMenuPageEffects {
  constructor(
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    private actions$: Actions,
    private consumerMenuApiService: ConsumerMenuApiService,
    private loadingController: LoadingController,
    private appStateFacadeService: AppStateFacadeService,
    private sessionStorageService: SessionStorageService,
    private consumerMenuFacadeService: ConsumerMenuFacadeService
  ) {
    this.baseApiUrl = `${this.environment.baseApiUrl}/web/`;
  }
  private baseApiUrl: string;

  getClickAndCollectSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(() => combineLatest([this.appStateFacadeService.getMerchantStore(), this.appStateFacadeService.getCart()])),
      filter(([merchantStore, cart]) => !!merchantStore && !!cart && cart.orderType === OrderTypes.clickCollect),
      take(1),
      map(() => appStateActions.getAvailableClickAndCollectSlots())
    )
  );

  getMenuCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(({ merchantStoreId }) =>
        this.consumerMenuApiService.getMerchantStoreConsumerMenuCategories(this.baseApiUrl, merchantStoreId).pipe(
          map((menuCategories) => consumerMenuApiActions.getMenuCategoriesSuccess({ menuCategories })),
          catchError((error) => of(consumerMenuApiActions.getMenuCategoriesFailure({ error })))
        )
      )
    )
  );

  getMenuItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(({ merchantStoreId }) =>
        combineLatest([
          this.consumerMenuFacadeService.getSelectedCategory().pipe(filter((category) => !!category)),
          this.consumerMenuFacadeService.getSearchTerm(),
        ]).pipe(
          switchMap(([menuCategoryId, searchTerm]) => {
            const command$ = searchTerm
              ? this.consumerMenuApiService.getMenuItemsByName(this.baseApiUrl, merchantStoreId, searchTerm)
              : this.consumerMenuApiService.getMerchantStoreConsumerMenuItems(this.baseApiUrl, merchantStoreId, menuCategoryId);
            return command$.pipe(
              map((menuItems) => consumerMenuApiActions.getMenuItemsSuccess({ menuItems })),
              catchError((error) => of(consumerMenuApiActions.getMenuItemsFailure({ error })))
            );
          })
        )
      )
    )
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.addItem),
      mergeMap(async ({ item }) => {
        const loading = await this.loadingController.create({
          spinner: 'dots',
          cssClass: 'kp-loading',
          translucent: true,
          showBackdrop: false,
        });
        return { item, loading };
      }),
      switchMap(async ({ item, loading }) => {
        await loading.present();
        return consumerMenuAddonsActions.getAddons({ item });
      })
    )
  );

  removeStoreIdSessionStorageKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consumerMenuPageActions.enterPage),
        switchMap(() => this.sessionStorageService.getItem<string>(storeIdSessionStorageKey)),
        tap((storageItem) => {
          if (storageItem) {
            this.sessionStorageService.removeItem(storeIdSessionStorageKey);
          }
        })
      ),
    { dispatch: false }
  );

  getCartFromSessionStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consumerMenuPageActions.enterPage),
      switchMap(() => this.appStateFacadeService.getMerchantStore()),
      filter((merchantStore) => !!merchantStore),
      switchMap((merchantStore) =>
        from(this.sessionStorageService.getItem<UserSession>(merchantStore.merchantStoreId)).pipe(
          map((userSession) => userSession || {}),
          map(({ cart, table, clickCollectSlot, discount, acknowledgedEvents }) =>
            appStateActions.updateCartFromStorage({ cart, table, clickCollectSlot, discount, acknowledgedEvents })
          ),
          catchError((error) => of(appStateActions.skippingUpdatingCartFromStorage({ error })))
        )
      )
    )
  );
}
