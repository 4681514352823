import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kody-list-item-action',
  templateUrl: './list-item-action.component.html',
  styleUrls: ['./list-item-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemActionComponent {
  @Input() title: string;
  @Input() subtitle?: string;
  @Input() isLast: boolean;
}
