import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appStateReducer } from './reducers/app-state.reducer';
import { AppStateEffects } from './effects/app-state.effects';

@NgModule({
  imports: [
    StoreModule.forRoot({
      appState: appStateReducer,
    }),
    EffectsModule.forRoot([AppStateEffects]),
  ],
})
export class AppStateModule {}
