import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ClickCollectSlot } from '@libs/shared/types';
import { SlotSelectionModalComponent } from '../components/slot-selection-modal.component';

@Injectable({
  providedIn: 'root',
})
export class SlotSelectionModalService {
  constructor(private modalController: ModalController) {}

  async showModal(availableSlots: ClickCollectSlot[], selectedSlot: ClickCollectSlot): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: SlotSelectionModalComponent,
      cssClass: 'kp-modal-lg',
      backdropDismiss: true,
      componentProps: {
        availableSlots,
        selectedSlot,
      },
      showBackdrop: true,
    });
    await modal.present();
    return modal;
  }
}
