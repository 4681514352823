import { Injectable } from '@angular/core';
import { ItemAddonResponse } from '@libs/shared/types';
import { StoreFacade } from '@libs/shared/utilities';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { consumerMenuAddonsSelector } from '../selectors/consumer-menu-addons.selector';

@Injectable({
  providedIn: 'root',
})
export class ConsumerMenuAddonsFacadeService extends StoreFacade {
  constructor(protected store: Store) {
    super(store);
  }

  getAddons(): Observable<ItemAddonResponse> {
    return this.store.select(consumerMenuAddonsSelector.selectAddonModalAddonsState());
  }
}
