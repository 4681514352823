import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { featureFlagsActions } from '../actions/feature-flags.actions';
import { deviceActions } from '@libs/utility-device';
import { loginActions } from '@libs/feature-login';
import { appStateApiActions } from '@libs/app-kody-order/utility-app-state';

@Injectable()
export class FeatureFlagsEffects {
  constructor(private actions$: Actions, private featureFlagService: FeatureFlagService) {}

  watch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureFlagsActions.startWatching),
      switchMap(() => this.featureFlagService.flagChanges$()),
      map((flags) => featureFlagsActions.flagsChanged({ flags }))
    )
  );

  identifyKodyPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deviceActions.pingSuccess),
      switchMap(({ payload }) =>
        this.featureFlagService.identify({
          name: payload.fullName,
          key: `kodypay:${payload.userId}`,
          custom: {
            merchantId: payload.roles[0].merchantId,
          },
        })
      ),
      map(() => featureFlagsActions.userIdentified())
    )
  );

  identifyKodyOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appStateApiActions.getMerchantStoreSuccess),
      switchMap(({ merchantStore }) =>
        this.featureFlagService.identify({
          name: merchantStore.name,
          key: `kodyorder:${merchantStore.merchantStoreId}`,
          custom: {
            merchantId: merchantStore.merchantId,
          },
        })
      ),
      map(() => featureFlagsActions.userIdentified())
    )
  );

  forget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.logout),
      switchMap(() => this.featureFlagService.identify({ anonymous: true })),
      map(() => featureFlagsActions.userForgotten())
    )
  );
}
