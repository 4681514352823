import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, IonicModule, FontAwesomeModule],
  declarations: [ModalComponent],
  exports: [ModalComponent],
})
export class ModalComponentModule {}
