import { Inject, Injectable } from '@angular/core';
import { ApiError, Environment } from '@libs/shared/types';
import { createErrorHandler, SentryErrorHandler } from '@sentry/angular';
import { APP_ENVIRONMENT } from '../../../app-environment/tokens/app-environment.token';

@Injectable({
  providedIn: 'root',
})
export class ErrorMonitoringService {
  private readonly sentryErrorHandler: SentryErrorHandler;

  constructor(@Inject(APP_ENVIRONMENT) private environment: Environment) {
    this.sentryErrorHandler = createErrorHandler({
      // Don't log errors in console on production
      logErrors: this.environment.name !== 'production',
    });
  }

  handleError(error: string | Error | ApiError | unknown): void {
    this.sentryErrorHandler.handleError(error);
  }
}
