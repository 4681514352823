import { createAction, props } from '@ngrx/store';
import { Session } from '../../../interfaces/session.interface';

const setSessionUser = createAction('[Session] Set user session', props<{ payload: any }>());
const setSession = createAction('[Session] Set session', props<{ payload: Session }>());
const updateSessionUser = createAction('[Session] Update user session', props<{ payload: { [key: string]: string | boolean } }>());

export const sessionActions = {
  setSessionUser,
  setSession,
  updateSessionUser,
};
