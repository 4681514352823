import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlotSelectionModalComponent } from './components/slot-selection-modal.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalComponentModule } from '../components/modal/modals.module';
import { ListItemActionComponentModule } from '../../lists/list-item-action/list-item-action.module';
import { ToggleButtonComponentModule } from '../../buttons/toggle-button/toggle-button.module';
import { AvailableSlotPipeModule } from '@libs/shared/utilities';

@NgModule({
  declarations: [SlotSelectionModalComponent],
  imports: [
    CommonModule,
    ModalComponentModule,
    ListItemActionComponentModule,
    ToggleButtonComponentModule,
    IonicModule,
    FontAwesomeModule,
    AvailableSlotPipeModule,
  ],
  exports: [SlotSelectionModalComponent],
})
export class SlotSelectionModalModule {}
