import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { StepperService } from '@libs/shared/utilities';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

@Component({
  selector: 'kody-shared-ui-stepper-input',
  templateUrl: './stepper-input.component.html',
  styleUrls: ['./stepper-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepperInputComponent),
      multi: true,
    },
  ],
})
export class StepperInputComponent implements ControlValueAccessor<number> {
  @Input() increment = 5;
  @Input() label: string;
  @Input() min = 0;
  @Input() max = 1440;
  @Input()
  get value(): number {
    return this._value;
  }
  set value(newValue: number) {
    this._value = newValue;
    this.isMin = newValue <= this.min;
    this.isMax = newValue >= this.max;
  }
  _value: number;
  isMin = false;
  isMax = false;
  faMinusCircle = faMinusCircle;
  faPlusCircle = faPlusCircle;

  constructor(private stepperService: StepperService) {}

  onChange = (value: number): void => {};

  onTouched = (): void => {};

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  step({ increment, boundary, isAdding }: { increment: number; boundary: number; isAdding: boolean }): void {
    this.value = this.stepperService.step({ increment, boundary, isAdding, value: this.value });
    this.onChange(this.value);
  }
}
