import { RoleObject, User } from '@libs/shared/types';
import { createSelector } from '@ngrx/store';
import { MerchantType } from '../../enums/merchant-type.enum';
import { Session } from '../../interfaces/session.interface';

// TODO: Improve once we decide how to handle app state
const appState = (state: { appState: any }) => state.appState;

export const currentSession = createSelector(appState, (state: { session: Session }) => state?.session);

export const getLoggedRoleObj = createSelector(appState, (state: { role: RoleObject }) => {
  return state.role;
});

export const getLoggedMerchantType = createSelector(getLoggedRoleObj, (roleObj: RoleObject) => {
  if (roleObj.isRestaurant) return MerchantType.RESTAURANT;
  if (roleObj.isRetail) return MerchantType.RETAILER;

  return null;
});

// Not needed for consumer payment flow
export const getUser = createSelector(appState, (state: { user: User }) => state.user);

export const isAppInited = createSelector(appState, (state: { appInited: boolean }) => {
  return state.appInited;
});
