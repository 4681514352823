import { createAction, props } from '@ngrx/store';

const logout = createAction('[Login] User logged out', (skipAnimation: boolean = false) => ({ skipAnimation }));
const login = createAction('[Login] User login', props<{ email: string; password: string }>());
const enterPage = createAction('[Login] Enter page');
const leavePage = createAction('[Login] Leave page');
const forgotPassword = createAction('[Login] Forgot password');
const incorrectUserRole = createAction('[Login] Incorrect user role');

export const loginActions = {
  logout,
  login,
  enterPage,
  leavePage,
  forgotPassword,
  incorrectUserRole,
};
