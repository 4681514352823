import { Pipe, PipeTransform } from '@angular/core';
import { TimeService } from '../../services/time.service';

@Pipe({
  name: 'minutes',
})
export class MinutesPipe implements PipeTransform {
  constructor(private timeService: TimeService) {}
  transform(minutes: number, condensed?: boolean): string {
    return this.timeService.getDurationMessageFromMinutes(minutes, condensed);
  }
}
