import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticePopoverComponent } from './notice-popover.component';
import { IonicModule } from '@ionic/angular';
import { NoDataComponentModule } from '@libs/shared/ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [NoticePopoverComponent],
  imports: [CommonModule, IonicModule, NoDataComponentModule, FontAwesomeModule],
  exports: [NoticePopoverComponent],
})
export class NoticePopoverComponentModule {}
