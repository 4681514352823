import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreCategory, StoreItem } from '@libs/shared/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsumerMenuApiService {
  constructor(private httpClient: HttpClient) {}

  getMerchantStoreConsumerMenuCategories(baseApiUrl: string, merchantStoreId: string): Observable<StoreCategory[]> {
    const url = `${baseApiUrl}merchant-stores/${merchantStoreId}/categories`;
    return this.httpClient.get<{ categories: StoreCategory[] }>(url).pipe(map(({ categories }) => categories));
  }

  getMerchantStoreConsumerMenuItems(baseApiUrl: string, merchantStoreId: string, categoryId: string): Observable<StoreItem[]> {
    const url = `${baseApiUrl}merchant-stores/${merchantStoreId}/categories/${categoryId}/items`;
    return this.httpClient.get<{ items: StoreItem[] }>(url).pipe(map(({ items }) => items));
  }

  getMenuItemsByName(baseApiUrl: string, merchantStoreId: string, searchTerm: string): Observable<StoreItem[]> {
    const url = `${baseApiUrl}merchant-stores/${merchantStoreId}/items?nameContains=${searchTerm}`;
    return this.httpClient.get<StoreItem[]>(url);
  }
}
