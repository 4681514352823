/*
 * This should eventually move to a global state file as it is shared across features.
 */
import { Cart } from '@libs/shared/types';
import { createSelector } from '@ngrx/store';

const appState = (state: { appState: any }) => state.appState;

export const getCurrentCart = createSelector(appState, (state: { currentCart: Cart }) => {
  return state.currentCart;
});
