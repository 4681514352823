import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuCategoriesComponent } from './menu-categories.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipComponentModule, LoadingModule, LongPressModule, RippleDirectiveModule } from '@libs/shared/ui';

@NgModule({
  imports: [CommonModule, IonicModule, LoadingModule, ChipComponentModule, FontAwesomeModule, LongPressModule, RippleDirectiveModule],
  declarations: [MenuCategoriesComponent],
  exports: [MenuCategoriesComponent],
})
export class MenuCategoriesComponentModule {}
