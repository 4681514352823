import { APP_INITIALIZER } from '@angular/core';
import { Environment } from '@libs/shared/types';
import { APP_ENVIRONMENT, ErrorMonitoringService } from '@libs/shared/utilities';
import { FeatureFlagFacadeService } from '../services/feature-flag-facade/feature-flag-facade.service';
import { FeatureFlagService } from '../services/feature-flag/feature-flag.service';
import { featureFlagsActions } from '../store/actions/feature-flags.actions';

export const featureFlagInitializerFactory = (
  featureFlagService: FeatureFlagService,
  featureFlagFacadeService: FeatureFlagFacadeService,
  environment: Environment,
  errorMonitoringService: ErrorMonitoringService
) => {
  return async (): Promise<void> => {
    try {
      await featureFlagService.initialize(environment.launchDarklyClientId);

      const initialFlags = featureFlagService.flags();
      featureFlagFacadeService.dispatch(featureFlagsActions.startWatching({ initialFlags }));
    } catch (error) {
      errorMonitoringService.handleError(error);
    }
  };
};

export const FEATURE_FLAG_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: featureFlagInitializerFactory,
  multi: true,
  deps: [FeatureFlagService, FeatureFlagFacadeService, APP_ENVIRONMENT, ErrorMonitoringService],
};
