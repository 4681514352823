/*
 * THIS IS TEMPORARILY DUPLICATED WITH THE SAME ACTIONS FILE IN THE FEATURE-MENU LIB
 * ONCE THE ITEM COMPONENT HAS BEEN REFACTORED REMOVE THIS
 * REASON FOR DUPLICATION IS IT BREAKS THE BUILD OF THIS LIB IMPORTS FROM THE FEATURE-MENU LIB
 * SEE KOD-1520 FOR ITEM REFACTOR
 */
import { ItemAddonResponse, ItemGroupAddons, StoreItem } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

/** Do not use this action except in the legacy item component.  Use the app state action instead */
const updateCartItemQuantity = createAction(
  '[Consumer Addons Modal] Update cart item quantity',
  props<{ item: StoreItem; addons: ItemGroupAddons; quantity: number }>()
);

const addItem = createAction('[Consumer Addons Modal] Add item', props<{ item: StoreItem }>());

const openAddonModal = createAction('[Consumer Addons Modal] Open addon modal', props<{ item: StoreItem }>());

const addAddonsToCart = createAction('[Consumer Addons Modal] Add addons to cart', props<{ item: StoreItem; addons: ItemGroupAddons }>());

const leaveAddonsModal = createAction('[Consumer Addons Modal] Modal closed');

const getAddons = createAction('[Consumer Addons API] Get item addons', props<{ item: StoreItem }>());

const getAddonsSuccess = createAction('[Consumer Addons API] Get addons success', props<{ addons: ItemAddonResponse; item: StoreItem }>());

const getAddonsFailed = createAction('[Consumer Addons API] Get addons failed');

export const consumerMenuAddonsActions = {
  addItem,
  getAddons,
  updateCartItemQuantity,
  openAddonModal,
  getAddonsSuccess,
  getAddonsFailed,
  addAddonsToCart,
  leaveAddonsModal,
};
