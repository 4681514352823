import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PlatformType } from '../types/platform-type.type';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private platform: Platform) {}

  /**
   * @returns true if the current users device is Android
   */
  isAndroid(): boolean {
    return this.platform.is('android');
  }

  /**
   * @returns true if the current users device is iOS
   */
  isIos(): boolean {
    return this.platform.is('ios');
  }

  /**
   * @returns true if the current user is running the native app
   */
  isNative(): boolean {
    return this.platform.is('cordova') || this.platform.is('capacitor');
  }

  /**
   * @returns true if the current platform matches the platform type
   * passed to the method
   */
  is(platformType: PlatformType): boolean {
    return this.platform.is(platformType);
  }

  width(): number {
    return this.platform.width() as number;
  }
}
