import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[kodyFitText]',
})
export class FitTextDirective implements AfterViewInit, OnChanges {
  @Input('kodyFitText') text: string;
  @Input() compression = 1;
  @Input() minFontSize = 0;
  @Input() maxFontSize = Number.POSITIVE_INFINITY;

  private parent: HTMLElement;
  private lineHeight: string;
  private display: string;

  constructor(private el: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.parent = this.el.nativeElement.parentElement;

    const computed = getComputedStyle(this.el.nativeElement);
    this.lineHeight = computed.lineHeight;
    this.display = computed.display;
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.setFontSize();
  }

  ngAfterViewInit() {
    setTimeout(() => this.setFontSize(), 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.el.nativeElement.textContent = this.text;
      this.setFontSize();
    }
  }

  private setFontSize(): void {
    if (this.el.nativeElement.offsetHeight * this.el.nativeElement.offsetWidth !== 0) {
      // reset to default
      this.setStyles(10, '1', 'inline-block');
      // set new
      this.setStyles(this.calculateNewFontSize(), this.lineHeight, this.display);
    }
  }

  private calculateNewFontSize(): number {
    const ratio = 10 / this.el.nativeElement.offsetWidth;
    const parentStyle = getComputedStyle(this.parent);
    const parentWidth = this.parent.offsetWidth - parseFloat(parentStyle.paddingLeft) - parseFloat(parentStyle.paddingRight);

    return Math.max(Math.min((parentWidth - 6) * ratio * this.compression, this.maxFontSize), this.minFontSize);
  }

  private setStyles(fontSize: number, lineHeight: string, display: string): void {
    this.renderer.setStyle(this.el.nativeElement, 'fontSize', `${fontSize}px`);
    this.renderer.setStyle(this.el.nativeElement, 'lineHeight', lineHeight);
    this.renderer.setStyle(this.el.nativeElement, 'display', display);
  }
}
