import { Inject, Injectable } from '@angular/core';
import { colord } from 'colord';
import { DOCUMENT } from '@angular/common';
import { AppTheme, ColorType, CssStyleRule, Environment } from '@libs/shared/types';
import { APP_ENVIRONMENT } from '@libs/shared/utilities';

@Injectable({
  providedIn: 'root',
})
export class BrandingService {
  constructor(@Inject(DOCUMENT) public document: Document, @Inject(APP_ENVIRONMENT) private environment: Environment) {}

  headerImage = '';

  /**
   * Generate the styles for the app from the theme
   * @param {AppTheme} theme - The theme object containing the colors, fonts and images
   */
  setStyles(theme: AppTheme): void {
    const { primaryColor, secondaryColor, mode, ...fontAndHeader } = theme;

    this.generateColors(primaryColor, 'primary');

    // Secondary color is currently not used in the app
    if (secondaryColor) {
      this.generateColors(secondaryColor, 'secondary');
    }

    this.setFontAndHeader(fontAndHeader);

    if (mode === 'dark') {
      this.generateDarkTheme(primaryColor);
    }
  }

  /**
   * Generate the colors for the app from the theme
   * @param {string} color - The color hex code for the palette base color
   * @param {ColorType} type - The color type (primary or secondary)
   */
  generateColors(color: string, type: ColorType): void {
    const contrast = colord(color).isDark() ? '#fff' : '#000';
    const colors = {
      [`--ion-color-${type}`]: color,
      [`--ion-color-${type}-rgb`]: colord(color).toRgbString(),
      [`--ion-color-${type}-contrast`]: contrast,
      [`--ion-color-${type}-contrast-rgb`]: colord(contrast).toRgbString(),
      [`--ion-color-${type}-shade`]: colord(color).darken(0.07).toHex(),
      [`--ion-color-${type}-tint`]: colord(color).lighten(0.03).toHex(),
      [`--${type}-bg`]: colord(color).lighten(0.5).toHex(),
    };
    this.setCssRootVariable(colors);
  }

  /**
   * Create the object to set the font and header for the theme
   * @param {Object} fontAndHeader - The font and header properties for the theme
   * @param {string} fontAndHeader.fontFamily - The font family for the theme
   * @param {string} fontAndHeader.headerImage - The header image url for the theme
   */
  setFontAndHeader({ fontFamily, headerImage }: { fontFamily: string; headerImage: string }): void {
    const fontAndHeader = {};

    if (headerImage) {
      this.headerImage = `url(${this.environment.baseApiUrl}/${headerImage})`;
    }

    if (fontFamily) {
      const font = fontFamily.split(':')[0].trim().replace('+', ' ');
      fontAndHeader['--ion-font-family'] = font;
      fontAndHeader['--bold-font-family'] = font;
      const linkEl = this.document.createElement('link');
      linkEl.setAttribute('rel', 'stylesheet');
      linkEl.setAttribute('type', 'text/css');
      linkEl.setAttribute('href', `https://fonts.googleapis.com/css2?family=${fontFamily}`);
      this.document.head.appendChild(linkEl);
    }

    this.setCssRootVariable(fontAndHeader);
  }

  /**
   * Generates the dark color theme and overrides the css variables
   * @param {string} primaryColor - The primary theme color
   */
  generateDarkTheme(primaryColor: string): void {
    this.document.body.classList.add('dark-mode');
    const primaryDark = colord(primaryColor).darken(0.2).toHex();
    const dark = '#000';
    const light = '#fff';
    const darkColors = {
      ['--background']: dark,
      ['--ion-background-color']: dark,
      ['--ion-text-color']: light,
      ['--color']: light,
      ['--default-border-color']: '#272727',
      ['--primary-dark']: primaryDark,
      ['--ion-color-success-contrast']: '#0D3E22',
      ['--ion-color-danger-contrast']: '#3E0D0D',
    };
    this.setCssRootVariable(darkColors);
    this.setMetaThemeColor(dark);
  }

  /**
   * Sets the meta theme-color
   * @param {string} color - The color to set the meta-theme
   */
  setMetaThemeColor(color: string): void {
    const meta = this.document.createElement('meta');
    meta.name = 'theme-color';
    meta.content = color;
    this.document.head.appendChild(meta);
  }

  /**
   * Check if the app should be in dark mode
   */
  isDarkMode(): boolean {
    return this.document.body.classList.contains('dark-mode');
  }

  /**
   * Overrides the default Ionic color variables with the theme colors
   * @param {CssStyleRule} styles - The complete color palette for one of the theme colors
   */
  setCssRootVariable(styles: CssStyleRule): void {
    Object.entries(styles).forEach(([key, value]) => {
      this.document.documentElement.style.setProperty(key, value);
    });
  }
}
