/* eslint-disable @typescript-eslint/no-unsafe-call */
// TODO: Refactor to conform to a dumb component
import { Component, OnDestroy, OnInit, TrackByFunction, ViewEncapsulation } from '@angular/core';
import {
  AddonInvalidGroups,
  ItemAddon,
  ItemAddonGroup,
  ItemAddonResponse,
  ItemGroupAddonDict,
  ItemGroupAddons,
  StoreItem,
} from '@libs/shared/types';

import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { StepperService, NotificationService } from '@libs/shared/utilities';
import { ConsumerMenuAddonsFacadeService } from '../../+state/services/consumer-menu-addons-facade.service';
import { consumerMenuAddonsActions } from '../../+state/actions/consumer-menu-addons.actions';

@Component({
  selector: 'kody-consumer-addon-modal',
  templateUrl: './consumer-addon-modal.component.html',
  styleUrls: ['./consumer-addon-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsumerAddonModalComponent implements OnInit, OnDestroy {
  item: StoreItem; // Passed via the modal props
  itemAddons: ItemAddonResponse; // Passed via the modal props
  addons: ItemGroupAddons = {};
  totalAddonsInGroup: ItemGroupAddonDict = {};
  addonGroupsWithMinSelectionLimit: ItemAddonGroup[];
  readonly icon = { faPlus, faMinus };
  addonTrackByFn: TrackByFunction<ItemAddon> = (_, { merchantItemId }) => merchantItemId;
  addonGroupTrackByFn: TrackByFunction<ItemAddonGroup> = (_, { addonGroupId }) => addonGroupId;

  constructor(
    private consumerMenuAddonsFacadeService: ConsumerMenuAddonsFacadeService,
    private stepperService: StepperService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.addonGroupsWithMinSelectionLimit = this.itemAddons.addonGroups.filter(
      (group) => group.selectionLimitsEnforced && group.minSelectionLimit > 0
    );
  }

  toggle(addons: ItemGroupAddons, addon: ItemAddon, addonGroup?: ItemAddonGroup): void {
    if (addons[`${this.getUniqueAddonId(addon.merchantItemId, addonGroup)}`]) {
      this.removeAddon(addon.merchantItemId, addonGroup);
    } else {
      this.addAddon(addons, addon, addonGroup);
    }
    if (addonGroup) {
      this.updateTotalAddonsInGroup(addons, addonGroup);
    }
  }

  exchange(addons: ItemGroupAddons, addon: ItemAddon, addonGroup: ItemAddonGroup): void {
    const groupAddonKey = Object.keys(this.getAddonsInGroup(addons, addonGroup))[0];
    const key = groupAddonKey.split('+')[0];
    if (groupAddonKey) {
      this.removeAddon(key, addonGroup);
      this.addAddon(addons, addon, addonGroup);
    }
  }

  step(
    { increment, boundary, isAdding }: { increment: number; boundary: number; isAdding: boolean },
    addon: ItemAddon,
    addons: ItemGroupAddons,
    addonGroup?: ItemAddonGroup
  ): void {
    addons[`${this.getUniqueAddonId(addon.merchantItemId, addonGroup)}`].quantity = this.stepperService.step({
      increment,
      boundary,
      isAdding,
      value: addons[`${this.getUniqueAddonId(addon.merchantItemId, addonGroup)}`].quantity,
    });

    if (addons[`${this.getUniqueAddonId(addon.merchantItemId, addonGroup)}`]?.quantity === 0) {
      this.removeAddon(addon.merchantItemId, addonGroup);
    }

    if (addonGroup) {
      this.updateTotalAddonsInGroup(addons, addonGroup);
    }
  }

  // Creates a suffix to apply to the end of the addon item id to make it unique
  private getSuffix(addonGroup: ItemAddonGroup): string {
    return !addonGroup ? '+ungrouped' : `+${addonGroup.addonGroupId}`;
  }

  // Creates a uniques addon item id to prevent issues when multiple groups or un-grouped addons contain the same addon
  getUniqueAddonId(addonId: string, addonGroup?: ItemAddonGroup): string {
    const suffix = this.getSuffix(addonGroup);
    return `${addonId}${suffix}`;
  }

  // Adds the addon to the addon dictionary
  addAddon(addons: ItemGroupAddons, addon: ItemAddon, addonGroup?: ItemAddonGroup): void {
    addons[`${this.getUniqueAddonId(addon.merchantItemId, addonGroup)}`] = {
      quantity: 1,
      item: addon,
      addonGroupId: addonGroup?.addonGroupId,
    };
  }

  // Removes the addon from the addon dictionary
  removeAddon(merchantItemId: string, addonGroup?: ItemAddonGroup): void {
    delete this.addons[`${this.getUniqueAddonId(merchantItemId, addonGroup)}`];
  }

  // Adds group addons to their respective group in the dictionary
  updateTotalAddonsInGroup(addons: ItemGroupAddons, addonGroup: ItemAddonGroup): void {
    this.totalAddonsInGroup[addonGroup.addonGroupId] = {
      quantity: this.getTotalAddonsInGroup(addons, addonGroup),
      minSelectionLimit: addonGroup.minSelectionLimit || null,
      addonGroupName: addonGroup.addonGroupName,
    };
  }

  // Returns all the addons for a single group
  getAddonsInGroup(addons: ItemGroupAddons, addonGroup: ItemAddonGroup): ItemGroupAddons {
    return Object.fromEntries(
      Object.entries(addons).filter(([key]) => {
        const addon = addons[key];
        return addon.hasOwnProperty.call(addon, 'addonGroupId') && addons[key]['addonGroupId'] === addonGroup.addonGroupId;
      })
    );
  }

  // Returns the total quantity of addons associated with a single group
  getTotalAddonsInGroup(addons: ItemGroupAddons, addonGroup: ItemAddonGroup): number {
    const groupAddons = this.getAddonsInGroup(addons, addonGroup);
    const groupAddonValues = Object.values(groupAddons);
    return groupAddonValues.reduce((total, item) => total + item.quantity, 0);
  }

  // Returns the addons which have not met the groups minimum selection criteria
  getInvalidGroupAddons(totalAddonsInGroup: ItemGroupAddonDict): AddonInvalidGroups[] {
    const invalidGroupAddons: AddonInvalidGroups[] = [];
    Object.entries(totalAddonsInGroup).filter(([key]) => {
      const group = totalAddonsInGroup[key];
      if (group.minSelectionLimit) {
        if (group.quantity < group.minSelectionLimit) {
          invalidGroupAddons.push(group);
        }
      }
    });
    return invalidGroupAddons;
  }

  getValidationMessage(min: string | number, name: string): string {
    return `Please choose at least ${min} from ${name}`;
  }

  invalidGroupNotificationError(totalAddonsInGroup: ItemGroupAddonDict, addonGroupsWithSelectionLimit: ItemAddonGroup[]): string {
    const invalidGroups = this.getInvalidGroupAddons(totalAddonsInGroup);

    // Check if any addons that have been selected have not met the group min selection limit
    if (invalidGroups?.length) {
      return this.getValidationMessage(invalidGroups[0].minSelectionLimit, invalidGroups[0].addonGroupName);
    }

    // Check if there are addon groups with min selection limits without any addons
    if (addonGroupsWithSelectionLimit.length) {
      const group = addonGroupsWithSelectionLimit.find((addonGroup) => !this.totalAddonsInGroup[addonGroup.addonGroupId]);
      if (group) {
        return this.getValidationMessage(group.minSelectionLimit, group.addonGroupName);
      }
    }

    return '';
  }

  addToBasket(
    addons: ItemGroupAddons,
    item: StoreItem,
    totalAddonsInGroup: ItemGroupAddonDict,
    addonGroupsWithSelectionLimit: ItemAddonGroup[]
  ): void {
    const message = this.invalidGroupNotificationError(totalAddonsInGroup, addonGroupsWithSelectionLimit);

    if (message) {
      this.notificationService.danger({ message });
      return;
    }
    const flattenedAddonDict: ItemGroupAddons = {};
    Object.entries(addons).map(([key, value]) => {
      const newKey = key.split('+')[0];
      if (!flattenedAddonDict[newKey]) {
        flattenedAddonDict[newKey] = value;
      } else {
        flattenedAddonDict[newKey].quantity += value.quantity;
      }
    });
    this.consumerMenuAddonsFacadeService.dispatch(consumerMenuAddonsActions.addAddonsToCart({ item, addons: flattenedAddonDict }));
  }

  ngOnDestroy(): void {
    this.consumerMenuAddonsFacadeService.dispatch(consumerMenuAddonsActions.leaveAddonsModal());
  }
}
