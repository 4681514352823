<div class="store-closed-popover">
  <kody-no-data [title]="title" [message]="text">
    <svg xmlns="http://www.w3.org/2000/svg" width="93.03" height="101.519" viewBox="0 0 93.03 101.519">
      <g transform="translate(2.003 2.004)">
        <path
          class="close-light"
          d="M46.7,72.921A35.521,35.521,0,0,1,12.355,46.3H81.037A35.515,35.515,0,0,1,46.7,72.921Z"
          transform="translate(-2 -2)"
          fill="#1566d1"
        />
        <g [ngClass]="{ line: isDarkMode }">
          <path
            d="M257.668-4640.858a46.179,46.179,0,0,1-14.724-9.928,46.168,46.168,0,0,1-9.928-14.723,46.04,46.04,0,0,1-3.641-18.033v-.025a2,2,0,0,1,2-2h5.719L229.1-4709.5a2,2,0,0,1,.107-1.527,2,2,0,0,1,1.159-1.006,1.994,1.994,0,0,1,1.53.108,2,2,0,0,1,1,1.159l8.419,25.2H246.7l-10.956-32.8a2,2,0,0,1,.108-1.527,2,2,0,0,1,1.159-1.006,1.994,1.994,0,0,1,1.53.108,2,2,0,0,1,1,1.159l11.378,34.067h69.1a2,2,0,0,1,2,2v.025a46.061,46.061,0,0,1-3.641,18.033,46.187,46.187,0,0,1-9.928,14.723,46.166,46.166,0,0,1-14.724,9.928,46.461,46.461,0,0,1-18.033,3.641A46.456,46.456,0,0,1,257.668-4640.858Zm-11.893-12.756a42.168,42.168,0,0,0,13.455,9.072,42.474,42.474,0,0,0,32.949,0,42.176,42.176,0,0,0,13.454-9.072q.655-.653,1.279-1.333H244.5Q245.12-4654.267,245.775-4653.614Zm64.382-5.333a42.191,42.191,0,0,0,3-4.864H238.243a42.166,42.166,0,0,0,3,4.864Zm4.849-8.864a41.966,41.966,0,0,0,2.974-13.756H233.42a41.969,41.969,0,0,0,2.973,13.756Zm-65.529-13.754h0Z"
            transform="translate(-231 4719)"
            fill="#212121"
          />
          <path
            d="M70.92,2H0A2,2,0,0,1-2,0,2,2,0,0,1,0-2H70.92a2,2,0,0,1,2,2A2,2,0,0,1,70.92,2Z"
            transform="translate(9.24 88.649)"
            fill="#212121"
          />
          <path
            d="M88.647,2H0A2,2,0,0,1-2,0,2,2,0,0,1,0-2H88.647a2,2,0,0,1,2,2A2,2,0,0,1,88.647,2Z"
            transform="translate(0.378 97.514)"
            fill="#212121"
          />
          <path
            d="M79.923,32.812a9.868,9.868,0,0,1-6.8-2.653,6.075,6.075,0,0,0-8.589,0,9.988,9.988,0,0,1-13.563,0,6.059,6.059,0,0,0-3.79-1.761,6.106,6.106,0,0,0-3.815,1.764,10.038,10.038,0,0,1-13.6,0,5.988,5.988,0,0,0-4.3-1.779,2,2,0,0,1,0-4,9.868,9.868,0,0,1,6.8,2.653,6.094,6.094,0,0,0,8.606,0A10.249,10.249,0,0,1,46,24.5a2,2,0,0,1,.694-.124h.974a2,2,0,0,1,.7.125,10.2,10.2,0,0,1,5.107,2.531,6.044,6.044,0,0,0,8.561,0,10.019,10.019,0,0,1,13.585,0,6.094,6.094,0,0,0,8.606,0,9.868,9.868,0,0,1,6.8-2.653,2,2,0,0,1,0,4,5.988,5.988,0,0,0-4.3,1.779,9.867,9.867,0,0,1-6.8,2.654Z"
            transform="translate(-2 -2)"
            fill="#212121"
          />
          <path
            d="M79.923,22.755a9.867,9.867,0,0,1-6.8-2.654,6.074,6.074,0,0,0-8.589,0,9.987,9.987,0,0,1-13.563,0,6.058,6.058,0,0,0-3.79-1.762A6.1,6.1,0,0,0,43.367,20.1a10.037,10.037,0,0,1-13.6,0,5.987,5.987,0,0,0-4.3-1.779,2,2,0,0,1,0-4,9.867,9.867,0,0,1,6.8,2.654,6.093,6.093,0,0,0,8.606,0A10.248,10.248,0,0,1,46,14.446a2,2,0,0,1,.694-.124h.974a2,2,0,0,1,.7.125,10.2,10.2,0,0,1,5.107,2.531,6.043,6.043,0,0,0,8.56,0,10.018,10.018,0,0,1,13.585,0,6.093,6.093,0,0,0,8.606,0,9.867,9.867,0,0,1,6.8-2.654,2,2,0,1,1,0,4,5.987,5.987,0,0,0-4.3,1.779,9.867,9.867,0,0,1-6.8,2.652Z"
            transform="translate(-2 -2)"
            fill="#212121"
          />
        </g>
      </g>
    </svg>
  </kody-no-data>
  <ion-button class="store-closed-popover__button" expand="block" (click)="done()">
    Keep browsing
    <fa-icon class="end-icon" [icon]="faChevronRight"></fa-icon>
  </ion-button>
</div>
