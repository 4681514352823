import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShrinkingHeaderDirective } from './shrinking-header.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShrinkingHeaderDirective],
  exports: [ShrinkingHeaderDirective],
})
export class ShrinkingHeaderDirectiveModule {}
