<ion-grid>
  <ion-row class="alert-modal-title">
    <ion-col>
      <ion-text class="multipage-modal-title-text bold-text black-text"> Item information </ion-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="mtop15">
      <ion-text class="alert-modal-subtitle"> {{ item?.description }} </ion-text>
    </ion-col>
  </ion-row>
</ion-grid>
<ion-footer>
  <ion-row>
    <ion-col class="mbottom15">
      <ion-button expand="block" (click)="dismissModal()"> Done </ion-button>
    </ion-col>
  </ion-row>
</ion-footer>
