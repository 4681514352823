import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, Injector } from '@angular/core';
import { take } from 'rxjs/operators';
import { BrandingApiService } from '../services/branding-api.service';
import { BrandingService } from '../services/branding.service';
import { isEmpty } from 'lodash';
import { AppTheme } from '@libs/shared/types';
import { AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';

export const brandingInitializerFactory = (
  brandingService: BrandingService,
  brandingApiService: BrandingApiService,
  injector: Injector,
  appStateFacadeService: AppStateFacadeService
) => {
  return async (): Promise<void> => {
    try {
      const document = injector.get(DOCUMENT);
      const url = new URL(document.defaultView.location.href);
      let storeId = url.pathname.split('/')[2] || url.searchParams.get('storeId');
      if (!storeId) {
        const merchantStore = await appStateFacadeService.getMerchantStore().pipe(take(1)).toPromise();
        if (!merchantStore) return;

        storeId = merchantStore.merchantStoreId;
      }

      const theme: AppTheme = await brandingApiService.getTheme(storeId).pipe(take(1)).toPromise();

      if (theme && !isEmpty(theme)) {
        brandingService.setStyles(theme);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const BRANDING_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: brandingInitializerFactory,
  multi: true,
  deps: [BrandingService, BrandingApiService, Injector, AppStateFacadeService],
};
