import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemComponent } from './menu-item.component';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipComponentModule, StoreItemChipsComponentModule } from '@libs/shared/ui';
import { AngularResizeEventModule } from 'angular-resize-event';

@NgModule({
  imports: [CommonModule, IonicModule, FontAwesomeModule, ChipComponentModule, AngularResizeEventModule, StoreItemChipsComponentModule],
  declarations: [MenuItemComponent],
  exports: [MenuItemComponent],
})
export class MenuItemComponentModule {}
