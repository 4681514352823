import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { appStateActions, AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';
import { kodyOrderBaseRoutes, kodyOrderRoutes, OrderTypes, storeIdSessionStorageKey, UserSession } from '@libs/shared/types';
import { ErrorMonitoringService, SessionStorageService } from '@libs/shared/utilities';

@Injectable({ providedIn: 'root' })
export class AppLegacyRedirectGuard implements CanActivate {
  constructor(
    private navController: NavController,
    private sessionStorageService: SessionStorageService,
    private appStateFacadeService: AppStateFacadeService,
    private errorMonitoring: ErrorMonitoringService
  ) {}

  canActivate = async (): Promise<boolean> => {
    const url = new URL(document.defaultView.location.href);
    const urlSegments = url.pathname.split('/');
    if (!urlSegments.length) return true;
    // Handle legacy Order Confirmed Route
    if (urlSegments.length >= 3 && urlSegments[3] === 'order-confirm') {
      const orderId = urlSegments[4];
      // We have to store the storeId in session storage when the order is created as it is not retrievable from the redirect url
      const merchantStoreId = await this.sessionStorageService.getItem<string>(storeIdSessionStorageKey);
      if (merchantStoreId && orderId) {
        // as the table number and order type cannot be extracted from the path, checking if they are available in session storage
        let orderType: OrderTypes;
        let table: string;
        let userSession: UserSession;
        try {
          userSession = await this.sessionStorageService.getItem<UserSession>(merchantStoreId);
        } catch (err) {
          this.errorMonitoring.handleError(err);
        }
        orderType = userSession?.cart?.orderType || OrderTypes.counter;
        table = userSession?.table;
        this.appStateFacadeService.dispatch(appStateActions.enterApp({ merchantStoreId, orderType, table }));
        this.navController.navigateForward(kodyOrderRoutes.orderConfirmed(merchantStoreId, orderId), {
          queryParams: {
            'cko-session-id': url.searchParams.get('cko-session-id'),
          },
        });
      }
      return;
    }

    // Handle all other routes (table, counter and click-collect)
    if (urlSegments.length >= 1) {
      const route = urlSegments[1];
      const params = url.searchParams;
      const merchantStoreId = params.get('storeId');

      if (!merchantStoreId) {
        // ? navigate to default route with message?
        return;
      }

      let table: string;
      let orderType: OrderTypes;

      switch (route) {
        case 'table':
          table = params.get('table');
          orderType = OrderTypes.table;
          break;
        case 'counter':
          orderType = OrderTypes.counter;
          break;
        case 'click-collect':
          orderType = OrderTypes.clickCollect;
          break;
        default:
          orderType = OrderTypes.counter;
          break;
      }

      const needTablePrompt = merchantStoreId === '0419c3cb-9850-423f-a4a0-f08e707dd691'; // Wing Wah Coventry store id
      if (needTablePrompt) {
        table = null; // Ignore existing table number
        while (!table) {
          table = prompt('Please enter your table number').trim();
        }
      }

      this.appStateFacadeService.dispatch(appStateActions.enterApp({ merchantStoreId, orderType, table }));
      this.navController.navigateRoot([kodyOrderBaseRoutes.root, merchantStoreId, route], {
        queryParams: { table },
      });
    }
  };
}
