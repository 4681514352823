export enum PaymentMethods {
  Cybersource = 'cybersource',
  ApplePay = 'applepay',
  GooglePay = 'googlepay',
  // Deprecated legacy enum props
  CYBER_SOURCE = 'cybersource',
  ALIPAY = 'alipay',
  GOOGLEPAY = 'googlepay',
  APPLEPAY = 'applepay',
}
