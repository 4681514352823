<kody-shared-ui-modal title="Collection time" class="selection-slot-modal">
  <div kody-modal-content class="selection-slot-modal__content">
    <kody-list-item-action
      (click)="$event.preventDefault()"
      *ngFor="let slot of availableSlots; last as isLast; trackBy: slotTrackByFn"
      [title]="slot.time + ' - Store Pickup'"
      [subtitle]="slot.available | availableSlot"
      [isLast]="isLast"
    >
      <kody-toggle-button [toggled]="selectedSlot?.timeSlotId === slot.timeSlotId" (toggle)="selectSlot(slot)"> </kody-toggle-button>
    </kody-list-item-action>
  </div>
  <div kody-modal-footer>
    <ion-button expand="block" (click)="close(selectedSlot)" [disabled]="!selectedSlot?.timeSlotId"> Confirm time </ion-button>
  </div>
</kody-shared-ui-modal>
