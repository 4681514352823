import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  private _isVisible$ = new BehaviorSubject(true);

  constructor(@Inject(DOCUMENT) private document: Document) {
    // Handle native visibility (resume & pause events sent by Cordova)
    this.document.addEventListener('resume', () => this._isVisible$.next(true), false);
    this.document.addEventListener('pause', () => this._isVisible$.next(false), false);

    // Web visibility
    this.document.addEventListener('visibilitychange', () => {
      const documentVisible = this.document.visibilityState === 'visible';
      this._isVisible$.next(documentVisible);
    });
  }

  isVisible(): Observable<boolean> {
    return this._isVisible$.asObservable().pipe(distinctUntilChanged());
  }

  // Emits true when the app becomes visible
  whenVisible(): Observable<boolean> {
    return this.isVisible().pipe(filter((visible) => visible));
  }
}
