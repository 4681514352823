import { ItemAddonResponse } from '@libs/shared/types';

export const consumerMenuAddonsStateKey = 'consumer-menu-addons';

export interface ConsumerMenuAddonsState {
  addons: ItemAddonResponse;
}

export interface ConsumerMenuAddonsAppState {
  [consumerMenuAddonsStateKey]: ConsumerMenuAddonsState;
}
