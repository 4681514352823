import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FontAwesomeModule],
  declarations: [SearchComponent],
  exports: [SearchComponent],
})
export class SearchComponentModule {}
