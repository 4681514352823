import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationComponent } from './duration.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MinutesPipeModule } from '../../pipes/minutes/minutes.pipe.module';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, MinutesPipeModule],
  declarations: [DurationComponent],
  exports: [DurationComponent],
})
export class DurationComponentModule {}
