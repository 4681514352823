export enum ApplePayButtonType {
  Buy = 'buy',
  Donate = 'donate',
  Plain = 'plain',
  SetUp = 'set-up',
  Book = 'book',
  CheckOut = 'check-out',
  Subscribe = 'subscribe',
  AddMoney = 'add-money',
  Contribute = 'contribute',
  Order = 'order',
  Reload = 'reload',
  Rent = 'rent',
  Support = 'support',
  Tip = 'tip',
  TopUp = 'top-up',
  Continue = 'continue',
}
