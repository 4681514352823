import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kody-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent {
  @Input() toggled: boolean;
  @Input() disabled = false;
  @Output() toggle = new EventEmitter<void>();

  readonly faPlus = faPlus;
  readonly faCheck = faCheck;

  onToggle(): void {
    this.toggle.emit();
  }
}
