<ion-header translucent="false">
  <ion-toolbar class="kp-modal__title-container">
    <div class="kp-modal__title-container__wrapper">
      <h2 class="kp-modal__title" [ngClass]="'kp-modal__title--' + titleSize">{{ title }}</h2>
      <ion-button fill="clear" class="kp-modal__close-btn" (click)="modalController.dismiss()">
        <fa-icon [icon]="closeIcon"></fa-icon>
      </ion-button>
    </div>
    <ng-content select="[kody-modal-sub-header]" *ngIf="subheader" class="kp-modal__sub-header"></ng-content>
  </ion-toolbar>
</ion-header>

<div class="kp-modal__content">
  <ng-content select="[kody-modal-content]"></ng-content>
</div>

<ion-footer class="kp-modal__footer">
  <ng-content select="[kody-modal-footer]"></ng-content>
</ion-footer>
