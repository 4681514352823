import { Component, ChangeDetectionStrategy, ViewEncapsulation, TrackByFunction } from '@angular/core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { ClickCollectSlot } from '@libs/shared/types';

@Component({
  selector: 'kody-slot-selection-modal',
  templateUrl: './slot-selection-modal.component.html',
  styleUrls: ['./slot-selection-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SlotSelectionModalComponent {
  constructor(private modalController: ModalController) {}

  readonly faTimes = faTimes;

  availableSlots: ClickCollectSlot[]; // Passed via the modal props
  selectedSlot: ClickCollectSlot;
  slotTrackByFn: TrackByFunction<ClickCollectSlot> = (_, { timeSlotId }) => timeSlotId;

  selectSlot(slot: ClickCollectSlot): void {
    this.selectedSlot = slot;
  }

  close(selectedSlot?: ClickCollectSlot): void {
    this.modalController.dismiss(selectedSlot);
  }
}
