import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuHeaderComponent } from './menu-header.component';
import { IonicModule } from '@ionic/angular';
import { FitTextDirectiveModule } from '@libs/shared/ui';
import { DurationComponentModule } from '@libs/utility-date-time';

@NgModule({
  imports: [CommonModule, IonicModule, FitTextDirectiveModule, DurationComponentModule],
  declarations: [MenuHeaderComponent],
  exports: [MenuHeaderComponent],
})
export class MenuHeaderComponentModule {}
