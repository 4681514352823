import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService implements StorageService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  sessionStorage = this.document.defaultView.sessionStorage;

  getItem<T>(key: string): Promise<T> {
    const value: T = JSON.parse(this.sessionStorage.getItem(key));
    return Promise.resolve(value);
  }

  setItem<T>(key: string, value: T): Promise<void> {
    const result = this.sessionStorage.setItem(key, JSON.stringify(value));
    return Promise.resolve(result);
  }

  removeItem(key: string): Promise<void> {
    return Promise.resolve(this.sessionStorage.removeItem(key));
  }
}
