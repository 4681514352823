/*
 * This should eventually move to a global state file as it is shared across features.
 */
import { MerchantStore } from '@libs/shared/types';
import { createSelector } from '@ngrx/store';

const appState = (state: { appState: any }) => state.appState;

export const getCurrentRestaurant = createSelector(appState, (state: { currentRestaurant: MerchantStore }) => {
  return state.currentRestaurant;
});
