import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StepperUiComponent } from './stepper-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, FontAwesomeModule],
  declarations: [StepperUiComponent],
  exports: [StepperUiComponent],
})
export class StepperUiComponentModule {}
