import { Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagChangeset, LDUser } from 'launchdarkly-js-client-sdk';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlags } from '../../types/feature-flags.type';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private client: LDClient;

  async initialize(key: string): Promise<void> {
    if (this.client) {
      throw new Error('LaunchDarkly client already initialized');
    }

    this.client = initialize(key, {
      anonymous: true,
    });
    await this.client.waitForInitialization();

    this.client.track('Initialized');
  }

  async identify(user: LDUser, alias = true): Promise<void> {
    const previousUser = this.client.getUser();

    await this.client.identify(user);
    const newUser = this.client.getUser();

    if (alias && previousUser.key !== newUser.key) {
      this.client.alias(newUser, previousUser); // Associate the new user with the previous user
    }
  }

  flags(): FeatureFlags {
    return this.client.allFlags();
  }

  flagChanges$(): Observable<FeatureFlags> {
    return fromEvent<LDFlagChangeset>(this.client, 'change').pipe(
      // Only take the current (new) value
      map((flags) => Object.keys(flags).reduce((acc, key) => ({ ...acc, [key]: flags[key].current }), {}))
    );
  }
}
