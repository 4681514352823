import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntTelInputComponent } from './int-tel-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [IntTelInputComponent],
  imports: [CommonModule, FormsModule, NgxIntlTelInputModule, ReactiveFormsModule],
  exports: [IntTelInputComponent],
})
export class IntTelInputComponentModule {}
