import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'availableSlot',
})
export class AvailableSlotPipe implements PipeTransform {
  transform(availableSlots: number | null): string {
    return availableSlots === null || availableSlots > 0 ? 'Available' : 'Unavailable';
  }
}
