import { notificationsStoreKey } from './../models/notifications.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsState } from '../models/notifications.models';

const selectNotificationsState = createFeatureSelector<NotificationsState>(notificationsStoreKey);
const selectPushToken = createSelector(selectNotificationsState, (notificationsState: NotificationsState) => notificationsState?.pushToken);

export const notificationsSelectors = {
  selectPushToken,
};
