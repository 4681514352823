export const environment = {
  production: true,
  name: 'staging',
  baseDomainUrl: 'https://q-sta.kpy.io',
  baseApiUrl: 'https://api-staging.kodypay.com',
  checkoutAPIConf: {
    endpoint: 'https://api.sandbox.checkout.com',
  },
  googlePayEnvironment: 'TEST',
  applePayMerchantId: 'merchant.com.kodypay.applepay',
  sentryDsn: 'https://66a0679e05124d9b9ce6480b91a65888@o1085662.ingest.sentry.io/6509091',
  launchDarklyClientId: '62d80d287265001239878fc1',
};
