import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuCheckoutButtonComponent } from './menu-checkout-button.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule.forRoot()],
  declarations: [MenuCheckoutButtonComponent],
  exports: [MenuCheckoutButtonComponent],
})
export class MenuCheckoutButtonComponentModule {}
