import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ForgotPasswordPageState, LoginState, loginStateKey } from '../models/login-state.model';

const selectLoginState = createFeatureSelector<LoginState>(loginStateKey);
const selectForgotPasswordPageState = createSelector(selectLoginState, (state: LoginState) => state?.forgotPasswordPage);

const selectLoading = createSelector(selectForgotPasswordPageState, (state: ForgotPasswordPageState) => state?.loading);

export const forgotPasswordPageSelectors = {
  selectLoading,
};
