import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppTheme, Environment } from '@libs/shared/types';
import { APP_ENVIRONMENT } from '@libs/shared/utilities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandingApiService {
  constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private environment: Environment) {}

  getTheme(merchantStoreId: string): Observable<AppTheme> {
    const url = `${this.environment.baseApiUrl}/web/merchantStores/${merchantStoreId}/theme`;
    return this.http.get<AppTheme>(url);
  }
}
