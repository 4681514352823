<div class="kody-stepper">
  <ion-button
    [disabled]="isMin"
    class="kody-stepper__button"
    (click)="onStep(increment, min, false)"
    fill="clear"
    kp-object="decrement-btn"
  >
    <fa-icon [icon]="decrementIcon"></fa-icon>
  </ion-button>
  <span class="kody-stepper__value" *ngIf="!!value || value === 0; else loading">{{ value }}{{ label }}</span>
  <ng-template #loading><ion-skeleton-text class="kody-stepper__loading" animated></ion-skeleton-text></ng-template>
  <ion-button [disabled]="isMax" class="kody-stepper__button" (click)="onStep(increment, max)" fill="clear" kp-object="increment-btn">
    <fa-icon [icon]="incrementIcon"></fa-icon>
  </ion-button>
</div>
