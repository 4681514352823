import { Action, createReducer, on } from '@ngrx/store';
import { loginApiActions } from '../actions/login-api.actions';
import { loginActions } from '../actions/login.actions';
import { LoginPageState } from '../models/login-state.model';

const _loginPageReducer = createReducer<LoginPageState>(
  undefined,
  on(loginActions.enterPage, (state) => ({
    ...state,
    loading: false,
  })),
  on(loginActions.login, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  })),
  on(loginApiActions.unauthorised, (state) => ({
    ...state,
    loading: false,
    error: 'You have entered incorrect login information, please try again.',
  })),
  on(loginApiActions.loginFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(loginActions.incorrectUserRole, (state) => ({
    ...state,
    error: 'Login failed. Please check that you are not logging in with a CMS account',
    loading: false,
  })),
  on(loginApiActions.loginSuccess, (state) => ({
    ...state,
    error: undefined,
    loading: false,
  })),
  on(loginActions.leavePage, (state) => undefined)
);

export function loginPageReducer(state: LoginPageState, action: Action): any {
  return _loginPageReducer(state, action);
}
