import { kodyOrderBaseRoutes } from './kodyorder-base-routes.constant';

const { root, table, clickCollect, counter, payment, orderConfirmed } = kodyOrderBaseRoutes;
export const kodyOrderRoutes = {
  table: (merchantStoreId: string) => `${root}/${merchantStoreId}/${table}`,
  clickCollect: (merchantStoreId: string) => `${root}/${merchantStoreId}/${clickCollect}`,
  counter: (merchantStoreId: string) => `${root}/${merchantStoreId}/${counter}`,
  payment: (merchantStoreId: string, orderId: string) => `${root}/${merchantStoreId}/${payment}/${orderId}`,
  orderConfirmed: (merchantStoreId: string, orderId: string) => `${root}/${merchantStoreId}/${orderConfirmed}/${orderId}`,
};
