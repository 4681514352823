import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'kody-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input() page = false;
  @Input() type? = 'dots';
  @Input() color? = 'primary';
}
