import { Injectable } from '@angular/core';
import { ApiError } from '@libs/shared/types';

const errors = {
  invalidWorkingHours: '1001',
  invalidTimeslot: '1003',
  expiredDiscount: '4002',
  invalidDiscount: '4003',
  invalidRefundAmount: '4004',
};

type ErrorType = keyof typeof errors;

@Injectable({
  providedIn: 'root',
})
export class ErrorResponseService {
  private getApiErrorCode(error: ApiError): string {
    return error?.error?.errorCode || '0';
  }

  hasError(error: ApiError, ...types: ErrorType[]): boolean {
    return types.map((t) => errors[t]).includes(this.getApiErrorCode(error));
  }
}
