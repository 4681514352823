import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { appStateActions, AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';
import { kodyOrderBaseRoutes, OrderTypes, UserSession } from '@libs/shared/types';
import { ErrorMonitoringService, SessionStorageService } from '@libs/shared/utilities';
import { firstValueFrom, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppRoutingGuard implements CanActivate {
  constructor(
    private appStateFacadeService: AppStateFacadeService,
    private sessionStorageService: SessionStorageService,
    private errorMonitoring: ErrorMonitoringService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  canActivate = async (): Promise<boolean> => {
    const url = new URL(this.document.defaultView.location.href);
    const urlSegments = url.pathname.split('/');
    // if the url is a legacy route then do nothing and let the legacy route guard handle the redirection to a valid route
    if (urlSegments[1] !== kodyOrderBaseRoutes.root) {
      return firstValueFrom(of(true));
    }
    const merchantStoreId = urlSegments[2];
    const route = urlSegments[3];
    let orderType: OrderTypes;
    let table: string;
    switch (route) {
      case 'table':
        table = url.searchParams.get('table');
        orderType = OrderTypes.table;
        break;
      case 'counter':
        orderType = OrderTypes.counter;
        break;
      case 'click-collect':
        orderType = OrderTypes.clickCollect;
        break;
      default:
        // corner case for when user does not first land on the QR code (menu) route
        // as the table number and order type cannot be extracted from the path, checking if they are available in session storage
        let userSession: UserSession;
        try {
          userSession = await this.sessionStorageService.getItem<UserSession>(merchantStoreId);
        } catch (err) {
          this.errorMonitoring.handleError(err);
        }
        orderType = userSession?.cart?.orderType || OrderTypes.counter;
        table = userSession?.table;
        break;
    }
    const needTablePrompt = url.searchParams.has('promptTable');
    if (needTablePrompt) {
      table = null; // Ignore existing table number
      while (!table) {
        table = prompt('Please enter your table number').trim();
      }
      // Set the new table number in the url
      url.searchParams.set('table', table);
      url.searchParams.delete('promptTable');
      this.document.location.href = url.toString();
    }
    this.appStateFacadeService.dispatch(appStateActions.enterApp({ merchantStoreId, orderType, table }));

    return firstValueFrom(
      this.appStateFacadeService.getMerchantStore().pipe(
        filter((store) => !!store),
        map(() => true)
      )
    );
  };
}
