import { OrderNotification } from '../models/notifications.models';
import { createAction, props } from '@ngrx/store';

const newOrderReceived = createAction('[Push] New Order', props<OrderNotification>());
const newOrderReceivedOpened = createAction('[Push] New Order Opened', props<OrderNotification>());

const newManualOrderReceived = createAction('[Push] New Manual Order', props<OrderNotification>());
const newManualOrderReceivedOpened = createAction('[Push] New Manual Order Opened', props<OrderNotification>());

const newCcOrderReceived = createAction('[Push] New C&C Order', props<OrderNotification>());
const newCcOrderReceivedOpened = createAction('[Push] New C&C Order Opened', props<OrderNotification>());

const ccOrderCompleted = createAction('[Push] Order C&C complete', props<OrderNotification>());
const ccOrderCompletedOpened = createAction('[Push] Order C&C complete Opened', props<OrderNotification>());

const orderAccepted = createAction('[Push] Order accepted', props<OrderNotification>());
const orderAcceptedOpened = createAction('[Push] Order accepted Opened', props<OrderNotification>());

const orderDeclined = createAction('[Push] Order declined', props<OrderNotification>());
const orderDeclinedOpened = createAction('[Push] Order declined Opened', props<OrderNotification>());

const manualOrderAccepted = createAction('[Push] Manual order accepted', props<OrderNotification>());
const manualOrderAcceptedOpened = createAction('[Push] Manual order accepted Opened', props<OrderNotification>());

const manualOrderDeclined = createAction('[Push] Manual order declined', props<OrderNotification>());
const manualOrderDeclinedOpened = createAction('[Push] Manual order declined Opened', props<OrderNotification>());

const registerPushNotifications = createAction('[Push] Register Push Notifications');
const updatePushToken = createAction('[Push] Update Push Token', props<{ pushToken: string }>());
const registerPushNotificationsSuccess = createAction('[Push] Register Push Notifications Success');

export const pushNotificationActions = {
  newOrderReceived,
  newOrderReceivedOpened,
  newManualOrderReceived,
  newManualOrderReceivedOpened,
  newCcOrderReceived,
  newCcOrderReceivedOpened,
  ccOrderCompleted,
  ccOrderCompletedOpened,
  orderAccepted,
  orderAcceptedOpened,
  orderDeclined,
  orderDeclinedOpened,
  manualOrderAccepted,
  manualOrderAcceptedOpened,
  manualOrderDeclined,
  manualOrderDeclinedOpened,
  registerPushNotifications,
  registerPushNotificationsSuccess,
  updatePushToken,
};
