import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemActionComponent } from './list-item-action.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipComponentModule } from '../../chip/chip.module';
import { IonicModule } from '@ionic/angular';
import { StepperUiComponentModule } from '../../stepper/components/stepper-ui/stepper-ui.module';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, ChipComponentModule, IonicModule, StepperUiComponentModule],
  declarations: [ListItemActionComponent],
  exports: [ListItemActionComponent],
})
export class ListItemActionComponentModule {}
