import { PushNotificationSchema } from '@capacitor/push-notifications';
import { PaymentMethods } from '@libs/shared/types';

export const notificationsStoreKey = 'notifications';

export interface OrderNotification {
  restaurantOrderId: string;
  paymentProvider?: PaymentMethods;
  notification: PushNotificationSchema;
}

export interface NotificationsState {
  pushToken?: string;
}
