import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddOnListComponent } from './add-on-list.component';
import { TextModule } from '@libs/shared/utilities';

@NgModule({
  imports: [CommonModule, TextModule],
  declarations: [AddOnListComponent],
  exports: [AddOnListComponent],
})
export class AddOnListComponentModule {}
