import { Injectable } from '@angular/core';
import { ClickCollectSlot, Cart, MerchantStore, ItemGroup, ServiceChargeOption, Discount, AcknowledgedEvent } from '@libs/shared/types';
import { StoreFacade } from '@libs/shared/utilities';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { appStateSelectors } from '../selectors/app-state.selectors';

@Injectable({
  providedIn: 'root',
})
export class AppStateFacadeService extends StoreFacade {
  constructor(protected store: Store) {
    super(store);
  }

  getMerchantStore(): Observable<MerchantStore> {
    return this.store.select(appStateSelectors.selectMerchantStore);
  }

  getTable(): Observable<string> {
    return this.store.select(appStateSelectors.selectTable);
  }

  getCart(): Observable<Cart> {
    return this.store.select(appStateSelectors.selectCart);
  }

  getItemGroupsTotalPrice(): Observable<number> {
    return this.store.select(appStateSelectors.selectItemGroupsTotalPrice);
  }

  getCartCount(): Observable<number> {
    return this.store.select(appStateSelectors.selectCartCount);
  }

  getCurrentItemQuantity(): Observable<{ [key: string]: number }> {
    return this.store.select(appStateSelectors.selectCurrentItemQuantity);
  }

  getClickAndCollectSlot(): Observable<ClickCollectSlot> {
    return this.store.select(appStateSelectors.selectClickCollectSlot);
  }

  getAvailableClickAndCollectSlots(): Observable<ClickCollectSlot[]> {
    return this.store.select(appStateSelectors.selectAvailableClickCollectSlots);
  }

  getStoreItemGroups(): Observable<ItemGroup[]> {
    return this.store.select(appStateSelectors.selectStoreItemGroups);
  }

  getServiceChargeOptions(): Observable<ServiceChargeOption[]> {
    return this.store.select(appStateSelectors.serviceChargeOptions);
  }

  getServiceChargeEnabled(): Observable<boolean> {
    return this.store.select(appStateSelectors.serviceChargeEnabled);
  }

  getServiceChargeValue(): Observable<number> {
    return this.store.select(appStateSelectors.serviceChargeValue);
  }

  getServiceChargeFixed(): Observable<number> {
    return this.store.select(appStateSelectors.serviceChargeFixed);
  }

  getServiceChargePercent(): Observable<number> {
    return this.store.select(appStateSelectors.serviceChargePercent);
  }

  getServiceChargeUnlockThresholdPercent(): Observable<number> {
    return this.store.select(appStateSelectors.serviceChargeUnlockThresholdPercent);
  }

  getTotalPrice(): Observable<number> {
    return this.store.select(appStateSelectors.totalPrice);
  }

  getDiscount(): Observable<Discount> {
    return this.store.select(appStateSelectors.discount);
  }

  getAgeConfirmationRequired(): Observable<boolean> {
    return this.store.select(appStateSelectors.ageConfirmationRequired);
  }

  getAcknowledgedEvents(): Observable<AcknowledgedEvent[]> {
    return this.store.select(appStateSelectors.acknowledgedEvents);
  }
}
