import { Action, createReducer, on } from '@ngrx/store';
import { consumerMenuAddonsActions } from '../actions/consumer-menu-addons.actions';
import { ConsumerMenuAddonsState } from '../models/consumer-menu-addons.model';

export const initialConsumerMenuAddonsState: ConsumerMenuAddonsState = {
  addons: undefined,
};

const consumerMenuAddonsStateReducerFn = createReducer(
  initialConsumerMenuAddonsState,
  on(consumerMenuAddonsActions.openAddonModal, (state) => ({
    ...state,
  })),
  on(consumerMenuAddonsActions.getAddonsSuccess, (state, { addons }) => ({
    ...state,
    addons,
  })),
  on(consumerMenuAddonsActions.leaveAddonsModal, (state) => ({
    ...state,
    addons: undefined,
  }))
);

export function consumerMenuAddonsStateStateReducer(state: ConsumerMenuAddonsState, action: Action): ConsumerMenuAddonsState {
  return consumerMenuAddonsStateReducerFn(state, action);
}
