import { HttpErrorResponse } from '@angular/common/http';
import { StoreCategory, StoreItem } from '@libs/shared/types';
import { createAction, props } from '@ngrx/store';

const getMenuCategoriesSuccess = createAction(
  '[Consumer Menu API] Get consumer menu categories success',
  props<{ menuCategories: StoreCategory[] }>()
);

const getMenuCategoriesFailure = createAction(
  '[Consumer Menu API] Get consumer menu categories failure',
  props<{ error: HttpErrorResponse }>()
);

const getMenuItemsSuccess = createAction('[Consumer Menu API] Get consumer menu items success', props<{ menuItems: StoreItem[] }>());

const getMenuItemsFailure = createAction('[Consumer Menu API] Get consumer menu items failure', props<{ error: HttpErrorResponse }>());

const filterMenuCategoriesSuccess = createAction('[Consumer Menu API] Filter menu categories success', props<{ menuItems: StoreItem[] }>());

export const consumerMenuApiActions = {
  filterMenuCategoriesSuccess,
  getMenuCategoriesSuccess,
  getMenuCategoriesFailure,
  getMenuItemsSuccess,
  getMenuItemsFailure,
};
