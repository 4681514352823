<ion-item button detail="false" [lines]="isLast && 'none'">
  <ion-label>
    <div class="label-wrapper ion-text-wrap">
      <h2>{{ title }}</h2>
      <h3 *ngIf="subtitle">{{ subtitle }}</h3>
      <ng-content select="[kody-list-header]"></ng-content>
    </div>
  </ion-label>
  <ng-content></ng-content>
</ion-item>
