export const loginStateKey = 'login';

export interface LoginState {
  loginPage: LoginPageState;
  forgotPasswordPage: ForgotPasswordPageState;
}

export interface LoginPageState {
  error: string;
  loading: boolean;
}

export interface ForgotPasswordPageState {
  loading: boolean;
}
