import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faClock } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kody-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationComponent {
  faClock = faClock;

  @Input() minutes = 0;
  @Input() prefix = '';
  @Input() condensed = true;
}
