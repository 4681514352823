export const authHeaders = {
  /**
   * This header forces a request unsigned and with no authentication header.
   */
  unsignedHeaderKey: 'X-no-auth-unsigned',
  /**
   * This header forces a request signed and with no authentication header.
   */
  signedHeaderKey: 'X-no-auth-signed',
};
