import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreItemChipsComponent } from './store-item-chips.component';
import { ChipComponentModule } from '../chip/chip.module';

@NgModule({
  imports: [CommonModule, ChipComponentModule],
  declarations: [StoreItemChipsComponent],
  exports: [StoreItemChipsComponent],
})
export class StoreItemChipsComponentModule {}
