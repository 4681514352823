import { Action, combineReducers } from '@ngrx/store';
import { LoginState } from '../models/login-state.model';
import { forgotPasswordPageReducer } from './forgot-password-page.reducer';
import { loginPageReducer } from './login-page.reducer';

const _loginReducer = combineReducers<LoginState>({
  loginPage: loginPageReducer,
  forgotPasswordPage: forgotPasswordPageReducer,
});

export function loginReducer(state: LoginState, action: Action): LoginState {
  return _loginReducer(state, action);
}
