/** Deprecated in favour of the OrderType type */
export enum OrderTypes {
  manualPayment = 'manual_payment',
  counter = 'order_at_counter',
  table = 'order_at_table',
  clickCollect = 'order_click_collect',
  selfCheckout = 'self_checkout',

  // Allows the enum to work with interfaces typed to the old OrderTypes enum
  // TODO: Remove once no longer required
  /** Deprecated - use `manualPayment` */
  MANUAL_PAYMENT = 'manual_payment',
  /** Deprecated - use `restaurantCounter` */
  RESTAURANT_COUNTER = 'order_at_counter',
  /** Deprecated - use `table` */
  RESTAURANT_TABLE = 'order_at_table',
  /** Deprecated - use `clickCollect` */
  CLICK_COLLECT = 'order_click_collect',
  /** Deprecated - use `selfCheckout` */
  SELF_CHECKOUT = 'self_checkout',
}
