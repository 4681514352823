import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, TrackByFunction } from '@angular/core';
import { StoreCategory } from '@libs/shared/types';

@Component({
  selector: 'kody-menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuCategoriesComponent {
  @Input() categories: StoreCategory[];
  @Input() primaryColor? = 'primary';
  @Input() secondaryColor? = 'secondary';
  @Input() selectedCategory: string;
  @Input() endContent: TemplateRef<unknown>;

  @Output() longPressCategory = new EventEmitter<StoreCategory>();
  @Output() selectCategory = new EventEmitter<StoreCategory>();

  trackBy: TrackByFunction<StoreCategory> = (index, { restaurantMenuCategoryId }) => restaurantMenuCategoryId;
}
