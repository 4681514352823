import { Action, createReducer, on } from '@ngrx/store';
import { forgotPasswordPageActions } from '../actions/forgot-password.actions';
import { loginApiActions } from '../actions/login-api.actions';
import { ForgotPasswordPageState } from '../models/login-state.model';

const _forgotPasswordPageReducer = createReducer<ForgotPasswordPageState>(
  undefined,
  on(forgotPasswordPageActions.enterPage, (state: ForgotPasswordPageState) => ({
    ...state,
    loading: false,
  })),
  on(forgotPasswordPageActions.resetPassword, (state: ForgotPasswordPageState) => ({
    ...state,
    loading: true,
  })),
  on(loginApiActions.forgotPasswordSuccess, loginApiActions.forgotPasswordFailure, (state: ForgotPasswordPageState) => ({
    ...state,
    loading: false,
  })),
  on(forgotPasswordPageActions.leavePage, () => undefined)
);

export function forgotPasswordPageReducer(state: ForgotPasswordPageState, action: Action): any {
  return _forgotPasswordPageReducer(state, action);
}
