import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'kody-stepper-ui',
  templateUrl: './stepper-ui.component.html',
  styleUrls: ['./stepper-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperUiComponent {
  @Input() increment = 5;
  @Input() label: string;
  @Input() value: number;
  @Input() min = 0;
  @Input() max? = 1440;
  @Input() isMin? = false;
  @Input() isMax? = false;
  @Input() decrementIcon? = faMinusCircle;
  @Input() incrementIcon? = faPlusCircle;

  @Output() step = new EventEmitter<{ increment: number; boundary: number; isAdding: boolean }>();

  onStep(increment: number, boundary: number, isAdding = true): void {
    this.step.emit({ increment, boundary, isAdding });
  }
}
