import { AfterViewInit, Directive, ElementRef, Inject, Renderer2 } from '@angular/core';
import { WINDOW } from '@libs/shared/utilities';

@Directive({ selector: '[kodyRippleEffect]' })
export class RippleDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(WINDOW) private window: Window) {}

  ngAfterViewInit(): void {
    const { nativeElement } = this.elementRef;
    const ripple = this.renderer.createElement('ion-ripple-effect');
    const position = this.window.getComputedStyle(nativeElement as Element).getPropertyValue('position');

    position === 'static' && this.renderer.setStyle(nativeElement, 'position', 'relative');
    this.renderer.setStyle(nativeElement, 'cursor', 'pointer');
    this.renderer.addClass(nativeElement, 'ion-activatable');
    this.renderer.appendChild(nativeElement, ripple);
  }
}
